<template>
    <!-- <v-app>
      <v-main> -->
        <div :class="getAlerts.length > 0 && 'space-y-1 py-3 __alert shadow-sm '" class="fixed top-0 w-full backdrop-opacity-80 backdrop-blur-lg" style="z-index: 999;">
              <AlertComponent v-for="(alert, index) in getAlerts" :key="index" :alert="alert"/>
          </div>
        <router-view/>
      <!-- </v-main>
    </v-app> -->
  </template>
  
  <script>
    import { mapGetters } from 'vuex'
    import AlertComponent from '@/components/helper/AlertComponent.vue'
  
  export default {
    name: 'App',
    components: { 
      AlertComponent
     },
      computed: {
          ...mapGetters(['getAlerts']),
      }
  }
  </script>
  
  <style>
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
  
    html, body{
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
  
    body {
        display: flex;
        flex-direction: column;
        background-color: rgb(243 244 246);
    }
    .footer{
        flex-shrink: 0;
    }
  
    /* SCROLLBAR */
    ::-webkit-scrollbar { /* width */
    width: 6px;
    }
    ::-webkit-scrollbar-track { /* Track */
    background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb { /* Handle */
    background: #888;
    }
    ::-webkit-scrollbar-thumb:hover { /* Handle on hover */
    background: #555;
    }
    /* FIM SCROLLBAR */

    li {
      list-style-type: none;
    }
      
    </style>
    