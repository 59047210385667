<template>
      <div class="vue-modal">
          <div class="vue-modal-inner">
            <div class="vue-modal-content">
              <div id="authentication-modal" @click.self="SHOW_MODAL_DISCIPLINA(false)" tabindex="-1" aria-hidden="true" class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center">
                  <div class="relative p-4 w-full max-w-md h-full md:h-auto">
                      <div class="relative bg-white shadow" >
                          <button @click="SHOW_MODAL_DISCIPLINA(false)" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          </button>
                          <div class="py-6 px-6 lg:px-8">
                              <h3 class="mb-4 text-xl font-medium text-gray-900">Criar nova Disciplina</h3>
                              <form class="space-y-6" action="" @submit.prevent="createDisciplina($event)">
                                  <div>
                                      <label for="nome_disciplina" class="flex items-start mb-2 text-sm font-medium text-gray-900">Nome da Disciplina</label>
                                      <input v-model="$store.state.projects_disciplinas.newDisciplina" type="text" name="nome" id="nome_disciplina" class="bg-gray-50 text-gray-900 text-sm border border-gray-200 focus:outline-none focus:border-blue-400 block w-full p-2.5" autocomplete="off" @keydown.enter.stop.prevent required>
                                  </div> 
                                  <div>
                                      <label for="descricao" class="flex items-start mb-2 text-sm font-medium text-gray-900">Descrição <p class="text-xs py-0.5 px-2 ml-3 bg-slate-400 text-gray-50">Desativado</p></label>
                                      <input disabled type="text" name="descricao" id="descricao" placeholder="" class="bg-gray-200 border border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5">
                                  </div>
                                  <div>
                                      <label for="curso" class="flex items-start mb-2 text-sm font-medium text-gray-900">Curso</label>
                                      <select  v-model="idCurso"  name="curso" id="curso" class="bg-gray-50 border border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5" required>
                                        <option value="" selected disabled>Selecione um Curso</option>
                                        <option v-for="curso in cursos" :key="curso.id" :value="curso.id">{{ curso.nome }}</option>
                                      </select>
                                  </div>
                                  <div v-if="categorias != null">
                                      <label for="categoria" class="flex items-start mb-2 text-sm font-medium text-gray-900">Categoria</label>
                                      <select  v-model="idCategoria"  name="categoria" id="categoria" class="bg-gray-50 border border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5">
                                        <option value="" selected disabled>Selecione um Categoria</option>
                                        <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nome }}</option>
                                        <option value="" >Nenhuma Categoria</option>
                                      </select>
                                  </div>
                                  <div>
                                      <label for="disciplina_A" class="flex items-start mb-2 text-sm font-medium text-gray-900">Status</label>
                                      <div class="text-center grid grid-cols-1 gap-4 sm:grid-cols-2">
                                        <div>
                                            <input v-model="status_disciplina" class="sr-only peer" id="disciplina_A" type="radio" tabindex="-1" value="A" required/>
                                            <label for="disciplina_A" class="block w-full p-3 border border-gray-200 hover:outline-none hover:border-blue-400  peer-checked:border-blue-500 hover:bg-gray-50 peer-checked:ring-0 peer-checked:ring-blue-500" tabindex="0">
                                                <span class="text-sm font-medium"> Ativo </span>
                                            </label>
                                        </div>
                                        <div>
                                            <input v-model="status_disciplina" class="sr-only peer" id="disciplina_I" type="radio" tabindex="-1" value="I"/>
                                            <label for="disciplina_I" class="block w-full p-3 border border-gray-200 hover:outline-none hover:border-blue-400  peer-checked:border-blue-500 hover:bg-gray-50 peer-checked:ring-0 peer-checked:ring-blue-500" tabindex="0">
                                                <span class="text-sm font-medium"> Inativo </span>
                                            </label>
                                        </div>
                                    </div>
                                  </div>
                                  <button :disabled="btn_submit_disciplina_disabled" type="submit" class="w-full text-white bg-emerald-400 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium text-sm px-5 py-2.5 text-center">Criar</button>
                              </form>
                          </div>
                      </div>
                  </div>
                </div> 
            </div>
          </div>
          
      </div>
  </template>
  
  <script>
  import { mapMutations, mapGetters } from 'vuex'
  export default {
    name: "Modal_New_Disciplina",
    props: {
        projeto: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            id_disciplina: null,
            cursos:null,
            categorias: null,

            idCurso: "",
            idCategoria: "",
            status_disciplina: "A",

            btn_submit_disciplina_disabled: false
        };
    },
    methods: {
        ...mapMutations(['SET_ALERT', 'SHOW_MODAL_DISCIPLINA']),
        ...mapGetters(['getNewDisciplina']),
 
        async getCategorias() {
            const req_categoria = await fetch(`${process.env.VUE_APP_URL}/categoria/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'DataBase': this.projeto
                },
            });
            const categorias = await req_categoria.json();
            this.categorias = categorias.data;
        },
        async getCursos() {
            const req_cursos = await fetch(`${process.env.VUE_APP_URL}/curso/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'DataBase': this.projeto
                },
            });
            const cursos = await req_cursos.json();
            this.cursos = cursos.data;
        },
        async createDisciplina(e) {
            e.preventDefault();
            this.btn_submit_disciplina_disabled = true;
            try {
                var body = {
                    nome : this.getNewDisciplina(),
                    idCurso : this.idCurso,
                    idCategoria : this.idCategoria,
                    status : this.status_disciplina
                }

                const req = await fetch(`${process.env.VUE_APP_URL}/disciplina/add/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'DataBase': this.projeto
                    },
                    body: JSON.stringify(body)
                });
             
                await req.json().then((res) => {
                    if (res.error) {
                        this.SET_ALERT({ heading: 'erro', message: res.error });
                    }else{
                        this.SET_ALERT({ heading: 'success', message: res.msg });
                        this.SHOW_MODAL_DISCIPLINA(false);
                        this.idCurso = "";
                        this.idCategoria = "";
                    }

                    setTimeout(() => {
                        this.btn_submit_disciplina_disabled = false
                    }, 5000)

                }).catch((error) => {
                    console.log("error_msg", "Houve um erro:" + error);
                });
            }
            catch (error) {
                console.log("error_msg", "Houve um erro:" + error);
            }
        }
    },
    mounted() {
        this.getCursos();
        this.getCategorias();
    },
};
  </script>