<template>
    <!-- <p class="text-gray-400 font-semibold text-lg -mb-12">Relatorio Diário (Data x Inscrições)</p> -->
    <div ref="chart" style="height: 30vh; width: 50%;"  class=""></div>
</template>
<script>
// import {echarts} from 'echarts';
import * as echarts from 'echarts';
import { mapActions } from 'vuex'

export default {
  name: "Line_Grafico",
  props: {
    db: {
      type: Array,
      required: true
    }
  },
  methods: {
  ...mapActions([ 'fetchInscricoesWeek' ]),
  },
  created(){
    this.fetchInscricoesWeek({db: this.db}).then((res) => {
      const data = res.map(item => [item.day_of_week, item.progress_count]);
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption({
          title: {
            text: 'Inscrições diárias',
            subtext: '(Data x Inscrições)',
            x: 'center'
          },
          xAxis: {
            type: 'category',
            data: data.map(item => item[0]) // Use a propriedade state como categoria no eixo x
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: data,
            type: 'line'
          }],
          tooltip: {
            trigger: 'item',
            formatter: "Dia {c} Inscrições"
          },
      });
    })
  },
}
</script>