<template>

    <section class="bg-gray-100">
        <div class="main-container px-4 py-10 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                <div class="lg:py-12 lg:col-span-2">
                    <div>
                        <router-link :to="{ name: 'aulas', params:{  projeto }}" class="text-2xl font-bold text-sky-600 capitalize">
                            {{projeto}} 
                        </router-link>
                    </div>
                    <div class="mt-8">
                        <router-link to="/" class="text-lg font-bold text-sky-500">
                            Unimagem LTDA
                        </router-link>
                        <address class="mt-2 text-sm not-italic">Escritório Comercial / Desenvolvimento e Produção. Rua Gustavo Maciel, 22-40. Sala 54. <br>17012-110 - Jardim Nasralla, Bauru-SP</address>
                    </div>
                </div>

                <div class="p-8 mr-10 bg-white shadow-lg lg:p-12 lg:col-span-3 relative">
                    <nav class="flex p-1 mb-8 text-gray-400 rounded-lg bg-gray-50">
                        <ol class="inline-flex items-center space-x-1 md:space-x-3">
                            <li class="inline-flex items-center group">
                                <router-link :to="{ name: 'aulas', params:{ projeto }}" class="inline-flex items-center text-sm font-medium capitalize text-gray-400 hover:text-gray-900">
                                    <svg class="w-4 h-4 mr-2 mb-1 text-gray-400 group-hover:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    {{projeto}}
                                </router-link>
                            </li>
                        
                            <li aria-current="page">
                            <div class="flex items-center">
                                <svg class="w-6 h-6 text-gray-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-400">
                                    Formulário Aula
                                </span>
                            </div>
                            </li>
                        </ol>
                    </nav>
                    <form action="" class="space-y-4" @submit.prevent="submitAula($event)">
                        <div v-if="id_aula">
                            <label class="sr-only" for="nome">ID</label>
                            <input v-model="$route.params.id_aula" class="w-full p-3 h-10 text-sm border border-gray-200 focus:outline-none bg-gray-100" type="text" disabled />
                        </div>
                        <div>
                            <label class="sr-only" for="nome">Título</label>
                            <input v-model="nome" class="w-full p-3 h-10 text-sm border border-gray-200 focus:outline-none focus:border-blue-400" placeholder="Título" type="text" id="nome" required @keydown.enter.stop.prevent/>
                        </div>
                        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <label class="sr-only" for="link">Link Vimeo</label>
                                <input v-model="link" class="w-full p-3 text-sm border border-gray-200 focus:outline-none focus:border-blue-400" placeholder="Link" type="link" id="link" required @keydown.enter.stop.prevent/>
                            </div>

                            <div>
                                <label class="sr-only" for="data">Data</label>
                                <input v-model="data" class="w-full p-3 text-sm border border-gray-200 focus:outline-none focus:border-blue-400" placeholder="Data" type="date" id="data" required @keydown.enter.stop.prevent/>
                            </div>
                        </div>
                        <div class="group relative border border-gray-200 ">
                            <div class="my-1 text-left px-2 pt-1">
                                <span  v-for="(op, index_op) in idAutor" :key="index_op"
                                    class="inline-flex px-2 pt-1 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded">
                                    {{op.nome}}
                                    <button @click="adicionarOuRemoverAutor(op)" type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900">
                                        <svg aria-hidden="true" class="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span class="sr-only">Remove badge</span>
                                    </button>
                                </span>
                            </div>
                            <input v-model="buscaAutor"  class="w-full relative px-3 pb-3 pt-1 text-sm  focus:outline-none focus:border-blue-400" 
                                id="dropdownCheckBox" placeholder="Autor(s)" type="text" autocomplete="off" 
                                @keydown.enter.stop.prevent
                                @input="inputHandlerAutores()">
                            <div id="dropdownRadio" class="hidden group-hover:block delay-100 absolute z-10 w-full bg-white rounded divide-y divide-gray-100 shadow-xl border border-gray-200 max-h-[50vh] overflow-y-auto">
                                <ul class="space-y-1 text-sm text-gray-700">
                                    <li v-for="(op, index_op) in autores" :key="index_op" @click="adicionarOuRemoverAutor(op), clearBuscaAutor()">
                                        <div :class="{'bg-gray-200': isSelected(op)}" class="flex items-center p-2 hover:bg-gray-100">
                                            <input type="checkbox" :checked="isSelected(op)" class="hidden">
                                            <label for="periodo_1" class="text-left ml-2 w-full text-sm font-medium text-gray-900">
                                                {{op.nome}}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="group relative">
                            <input v-model="idDisciplina.nome"  class="w-full relative p-3 text-sm border border-gray-200 focus:outline-none focus:border-blue-400" 
                                placeholder="Disciplina" type="text" autocomplete="off" required 
                                @keydown.enter.stop.prevent
                                @input="inputHandlerDisciplina()">
                            <div class="hidden group-hover:block delay-100 absolute z-10 w-full bg-white rounded divide-y divide-gray-100 shadow-xl border border-gray-200 max-h-[50vh] overflow-y-auto">
                                <ul class="space-y-1 text-sm text-gray-700" aria-labelledby="dropdownRadioButton">
                                    <li v-for="(op, index_op) in disciplina" :key="index_op" @click="idDisciplina = op">
                                        <div :class="{'bg-gray-200': idDisciplina.id == op.id}" class="flex items-center p-2 hover:bg-gray-100">
                                            <input type="radio" v-bind:checked="idDisciplina.id == op.id" class="hidden">
                                            <label for="periodo_1" class="text-left ml-2 w-full text-sm font-medium text-gray-900">
                                                {{op.nome}}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-center grid grid-cols-1 gap-4 sm:grid-cols-3">
                            <div>
                                <input v-model="status" class="sr-only peer" id="option1" type="radio" tabindex="-1" value="A"  required @keydown.enter.stop.prevent />
                                <label for="option1" class="block w-full p-3 border border-gray-200 hover:outline-none hover:border-blue-400  peer-checked:border-blue-500 hover:bg-gray-50 peer-checked:ring-0 peer-checked:ring-blue-500" tabindex="0">
                                    <span class="text-sm font-medium"> Ativo </span>
                                </label>
                            </div>

                            <div>
                                <input v-model="status" class="sr-only peer" id="option2" type="radio" tabindex="-1" value="I"/>
                                <label for="option2" class="block w-full p-3 border border-gray-200 hover:outline-none hover:border-blue-400  peer-checked:border-blue-500 hover:bg-gray-50 peer-checked:ring-0 peer-checked:ring-blue-500" tabindex="0">
                                    <span class="text-sm font-medium"> Inativo </span>
                                </label>
                            </div>

                        </div>

                        <div>
                            <label class="sr-only" for="descricao">Descrição</label>
                            <textarea v-model="descricao" class="w-full p-3 text-sm border border-gray-200 focus:outline-none focus:border-blue-400" placeholder="Descrição (Opcional)" rows="8" id="descricao"></textarea>
                        </div>

                        <div class="mt-4">
                            <button :disabled="btn_submit_disabled" type="submit" :class="btn_submit_disabled ? 'bg-gray-300 w-full px-5 py-3 cursor-not-allowed' : 'bg-sky-600 hover:bg-sky-700 inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-white transition'">
                                <span class="font-medium"> Enviar Publicação </span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                        </div>
                    </form>
                    <div class="absolute flex items-start rotate-90 right-[-148px] top-[300px]">
                        <a @click="SHOW_MODAL_AUTORES(true)" href="javascript:void(0);" class="flex items-center border-b-[3px] border-transparent px-4 py-3 bg-gray-200 text-gray-500 hover:border-blue-500 hover:bg-gray-50  hover:text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" /></svg>
                            <span class="ml-3 text-sm font-medium"> Autor </span>
                        </a>

                        <a @click="SHOW_MODAL_DISCIPLINA(true)" href="javascript:void(0);" class="flex ml-3 items-center border-b-[3px] border-transparent px-4 py-3 bg-gray-200 text-gray-500 hover:border-blue-500 hover:bg-gray-50 hover:text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" /></svg>
                            <span class="ml-3 text-sm font-medium"> Disciplina </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Modal_New_Autor v-show="getShowModalNewAutor()" class="relative" :projeto="projeto"/>
        <Modal_New_Disciplina v-show="getShowModalnewDisciplina()" :projeto="projeto" />
    </section>
   
</template>

<script>
import Modal_New_Disciplina from "./Modal/Modal_New_Disciplina.vue";
import Modal_New_Autor from "./Modal/Modal_New_Autor.vue";
import debounce from 'lodash/debounce';
import { mapMutations, mapGetters } from 'vuex'

    export default {
    name: "Form_Aula",
    props: {
        id_aula: {
            type: Number,
            required: false,
            default: 0
        },
        projeto: {
            type: String,
            required: true
        }
    },
    components: { Modal_New_Disciplina, Modal_New_Autor },
    data() {
        return {
            nome: "",
            link: "",
            data: "",
            idAutor: [],
            idDisciplina: {
                nome: ""
            },
            status: "A",
            descricao: "",

            disciplina: [],
            autores: [],
            msg: "",
            success: null,
            btn_submit_disabled: false,

            datalistDisciplinas: false,
            buscaAutor: ''
        };
    },
    watch: {
        isUpdating (val) {
            if (val) {
                setTimeout(() => (this.isUpdating = false), 3000)
            }
        }
    },
    methods:{
        ...mapGetters(['getShowModalNewAutor', 'getShowModalnewDisciplina']),
        ...mapMutations(['SET_ALERT', 'SHOW_MODAL_AUTORES', 'SHOW_MODAL_DISCIPLINA']),

        async getAula() {

            const req_aula = await fetch(`${process.env.VUE_APP_URL}/aulas/${this.id_aula}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'DataBase': this.projeto
                },
            });

            const aula_json = await req_aula.json();
            const aula = aula_json.data.aula;
            const autor = aula_json.data.autor;

            this.nome           = aula.nome,
            this.link           = aula.link,
            this.data           = aula.data,
            this.status         = aula.status,
            this.descricao      = aula.descricao
            this.idAutor        = autor

            const disciplina = {...this.disciplina.find(disciplina => disciplina.id === aula.idDisciplina)};
            this.idDisciplina = disciplina;

        },

        async close_disciplina(isOpenDisciplina) {
            this.getDisciplina();
            return isOpenDisciplina;
        },

        async getAutores() {
            const req_autores = await fetch(`${process.env.VUE_APP_URL}/autor/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'DataBase': this.projeto
                },
                body: JSON.stringify({ busca: this.buscaAutor }), 
            });
            const autores_json = await req_autores.json();

            this.autores = autores_json.data;
        },

        inputHandlerAutores: debounce(async function() {
            await this.getAutores();
        }, 500),

        isSelected(autor) {
            return this.idAutor.some(a => a.id === autor.id);
        },

        clearBuscaAutor(){
            if (this.buscaAutor == '') return

            this.buscaAutor = '';
            this.getAutores();
        },

        adicionarOuRemoverAutor(autor) {
            const index = this.idAutor.findIndex(a => a.id === autor.id);
            if (index !== -1) {
                this.idAutor.splice(index, 1);
            } else {
                this.idAutor.push(autor);
            }
        },
        
        async getDisciplina() {
            await fetch(`${process.env.VUE_APP_URL}/disciplina/buscar`, {
            method: 'POST', // altera o método para POST
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'DataBase': this.projeto,
            },
            body: JSON.stringify({ busca: this.idDisciplina.nome }), // adiciona o corpo da solicitação
            }).then((data) => {
                data.json().then((disciplinas) => {
                    this.disciplina = disciplinas.data;
                });
            }).catch((error) => {
                console.error('Erro:', error);
            });

        },

        inputHandlerDisciplina: debounce(async function() {
            await this.getDisciplina();
        }, 500),

        closeDatalistDisciplinas() {
            this.datalistDisciplinas = false
        },
        async submitAula(e) {
            e.preventDefault();
            this.btn_submit_disabled = true;
            if(!this.status || typeof this.status == undefined || this.status == null) {
                this.SET_ALERT({ heading: 'erro', message: 'Por favor, preencha o campo Status.' });
                this.btn_submit_disabled = false
                return;
            }
            if (!this.idAutor.length || !this.autores.some(a => a.id === this.idAutor[0].id) ) {
                this.SET_ALERT({ heading: 'erro', message: 'Por favor, preencha o campo Autor.' });
                this.btn_submit_disabled = false
                return;
            }
            if(!this.disciplina.some(disciplina => disciplina.id == this.idDisciplina.id && disciplina.nome === this.idDisciplina.nome) ) {
                this.SET_ALERT({ heading: 'erro', message: 'Por favor, preencha o campo Disciplina.' });
                this.btn_submit_disabled = false
                return;
            }

            if(this.id_aula){ 
                this.updateAula() 
            }else {
                this.createAula();
            }
        },

        async updateAula() {
            try {
                var body = {
                    nome : this.nome,
                    link : this.link,
                    data : this.data,
                    idDisciplina : this.idDisciplina.id,
                    status : this.status,
                    descricao : this.descricao,
                    idAutor : this.idAutor
                }

                const req = await fetch(`${process.env.VUE_APP_URL}/aulas/${this.id_aula}`, {
                    method: 'PATCH',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'DataBase': this.projeto
                    },
                    body: JSON.stringify(body)
                });

                await req.json().then((res)=> {
                    if (res.error) {
                        this.SET_ALERT({ heading: 'erro', message: res.error });
                        setTimeout(() => {
                            this.btn_submit_disabled = false
                        }, 5000)
                    }else{
                        this.SET_ALERT({ heading: 'success', message: res.msg });
                        if (!res.error)  this.$router.push('/aulas/' + this.projeto)

                    }
                }).catch((erro) => {
                    console.log("error_msg", "Houve um erro:" + erro);
                    this.btn_submit_disabled = false;
                })
            } catch (error) {
                console.log("error_msg", "Houve um erro:" + error);
                this.btn_submit_disabled = false;
            }
        },

        async createAula() {
            try {
                var body = {
                    nome : this.nome,
                    link : this.link,
                    data : this.data,
                    idDisciplina : this.idDisciplina.id,
                    status : this.status,
                    descricao : this.descricao,
                    idAutor : this.idAutor
                }

                const req = await fetch(`${process.env.VUE_APP_URL}/aulas/add`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'DataBase': this.projeto
                    },
                    body: JSON.stringify(body)
                });

                await req.json().then((res)=> {
                    if (res.error) {
                        this.SET_ALERT({ heading: 'erro', message: res.error });
                        setTimeout(() => {
                            this.btn_submit_disabled = false
                        }, 5000)

                    }else{
                        this.SET_ALERT({ heading: 'success', message: res.msg });
                        if (!res.error)  this.$router.push('/aulas/' + this.projeto)

                    }
                }).catch((erro) => {
                    console.log("error_msg", "Houve um erro:" + erro);
                    this.btn_submit_disabled = false;
                })
            } catch (error) {
                console.log("error_msg", "Houve um erro:" + error);
                this.btn_submit_disabled = false;
            }
        },

        remove (item) {
            const index_Autor = this.idAutor.indexOf(item.name)
            if (index_Autor >= 0) this.idAutor.splice(index_Autor, 1)
      
            const index_Disciplina = this.idDisciplina.indexOf(item.name)
            if (index_Disciplina >= 0) this.idDisciplina.splice(index_Disciplina, 1)
        },

    },
    mounted() {
        this.popupItem = this.$el
        this.getAutores();
        this.getDisciplina();
        if(this.id_aula) {
            this.getAula();
        }
    }
}
</script>
