// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import project from './modules/project';
import inscricoes from './modules/inscricoes';
import projects_autores from './modules/projects_autores';
import projects_disciplinas from './modules/projects_disciplinas';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        project,
        inscricoes,
        projects_autores,
        projects_disciplinas
    }
})
