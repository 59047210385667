<template>
  <div class="multi-progress-bar">
    <div class="relative space-y-1 text-xs font-medium text-blue-100 text-center leading-none">
        <div class="group/medico flex items-center relative cursor-pointer">
            <div class="hover:scale-x-[1.02] whitespace-nowrap p-1 bg-blue-600" :style="{ width: this.getInscricoesProfession[0].percentage + '%' }">
                {{ this.getInscricoesProfession[0].percentage }} %  
            </div>
            <span class="ml-3 text-white bg-black/20 px-2 py-0.5 whitespace-nowrap ">
                {{ this.getInscricoesProfession[0].profession }}
            </span> 
            <div class="hidden group-hover/medico:inline-block absolute z-10 top-0 -right-16 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm m-1">
                <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <h3 class="font-semibold text-gray-900">Especialidades Médicas</h3>
                </div>
                <div class="p-3 space-y-2 text-left">
                    <div v-for="(specialty, index) in getInscricoesSpecialty" :key="index">
                        <p>{{ specialty.especialdade}} {{ specialty.percentage }} %</p> 
                        <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                            <div :class="'bg-blue-' + (7 - index) + '00 h-2.5 rounded-full'" :style="{ width: specialty.percentage + '%' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="group/farmaceutico flex items-center cursor-pointer relative">
            <div class="group-hover/farmaceutico:scale-x-125 whitespace-nowrap p-1 bg-blue-300" :style="{ width: this.getInscricoesProfession[1].percentage + '%' }">
                {{ this.getInscricoesProfession[1].percentage }} %  
            </div>
            <span class="ml-3 text-white bg-black/20 px-2 py-0.5 whitespace-nowrap">
                {{ this.getInscricoesProfession[1].profession }}
            </span> 
            <div class="hidden group-hover/farmaceutico:inline-block absolute z-10 top-5 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm m-1">
                <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
                    <h3 class="font-semibold text-gray-900">{{ this.getInscricoesProfession[1].profession }}</h3>
                </div>
                <div class="px-3 py-2">
                    <p>Porcentagem de {{ this.getInscricoesProfession[1].percentage }} %  </p>
                </div>
            </div>
        </div>
        <div class="group/bi flex items-center cursor-pointer relative">
            <div class="group-hover/bi:scale-x-125 whitespace-nowrap h-4 bg-yellow-500" :style="{ width: this.getInscricoesProfession[2].percentage + '%' }"></div>
            <span class="ml-3 text-white bg-black/20 px-2 py-0.5 whitespace-nowrap">
                {{ this.getInscricoesProfession[2].profession }} 
            </span> 
            <div class="hidden group-hover/bi:inline-block absolute z-10 top-5 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm m-1">
                <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
                    <h3 class="font-semibold text-gray-900">{{ this.getInscricoesProfession[2].profession }}</h3>
                </div>
                <div class="px-3 py-2">
                    <p>Porcentagem de {{ this.getInscricoesProfession[2].percentage }} %  </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
  
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: "MultiProgress",
        props: {
            db: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                profession: null,
                cores: ["bg-blue-600", "bg-blue-300", "bg-yellow-500"],
            }
        },
        methods: {
            ...mapActions([ 'fetchinscricoesProfession', 'fetchinscricoesSpecialty' ]),
        },
        async created(){
            this.fetchinscricoesProfession({db: this.db})
            this.fetchinscricoesSpecialty({db: this.db})
        },
        computed: {
            ...mapGetters(['getInscricoesProfession', 'getInscricoesSpecialty']),
        },
    }
</script>
 
<style>
.progress-bar-label {
    width: calc(100% / 3);
    text-align: center;
  }
  
</style>