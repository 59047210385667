import ProjectService from '../../services/ProjectService'
import router from '../../router'

const state = () => ({
    loadingProject: false,
    projects: JSON.parse(localStorage.getItem('projects')) || null
})
  
const mutations = {
    SET_PROJECTS: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('projects', JSON.stringify(payload.projects));
        state.projects = payload.projects
        state.token = payload.token
    },
    SET_LOADING_PROJECT: (state, payload) => {
        state.loadingProject = payload
    }
}

const actions = {
    async getPermissions({commit}){
        try{
            commit('SET_LOADING_PROJECT', true)
            const response = await ProjectService.getProjects();
            const projetos = response.data.projects
            commit('SET_PROJECTS', response.data)
            // O usuário possui apenas um projeto, dereciono direto para o projeto
            if(projetos.length == 1){
                router.push({path: `/${projetos[0].type}/${projetos[0].db}`});
            }
            commit('SET_LOADING_PROJECT', false)
            return response.data;
        } catch (error) {
            commit('SET_LOADING_PROJECT', false);
            commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
    
}

const getters = {
    getProjects: state => state.projects,
    getLoadingProject: state => state.loadingProject
}

export default {
    state,
    getters,
    mutations,
    actions
};