<template>
     <div class="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-sm shadow-md">
        <div :class="alert.heading === 'success' ? 'bg-green-500' : 'bg-red-500'" class="flex items-center justify-center w-12">
            <svg v-if="alert.heading === 'success'" class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
            </svg>
            <svg v-else class="w-6 h-6 text-white"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </div>
        
        <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
                <span :class="alert.heading === 'success' ? 'text-green-500' : 'text-red-500'" class="font-semibold capitalize">{{ alert.heading }}</span>
                <p v-html="alert.message" class="text-sm text-gray-600 dark:text-gray-200"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AlertComponent',
        props: {
            alert: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>