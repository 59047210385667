import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AulasView from '../views/AulasView.vue'
import FormAulasView from '../views/FormAulasView.vue'
import ImportadorView from '../views/ImportadorView.vue'
import InscricoessView from '../views/InscricoessView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true, // Define que essa rota requer autenticação
      requiresAdmin: false // Define que essa rota requer permissão de administrador
    }
    
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/aulas/:projeto',
    name: 'aulas',
    component: AulasView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/inscricoes/:projeto',
    name: 'inscricoes',
    component: InscricoessView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/importador/:projeto/:tabela',
    name: 'importador',
    component: ImportadorView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/form_aula/:projeto',
    name: 'form_aula',
    component: FormAulasView,
    children: [{
      path: ':id_aula', name: 'editar_aula', component: () => import(/* webpackChunkName: "aulas" */ '../views/AulasView.vue'),
    }],
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  
  if (requiresAuth || requiresAdmin) {
    const { user } = await store.dispatch('AuthUser')

    if (!user) {
      return next('/login');
    }

    if (requiresAdmin) {
        const permissions = user.permissions
        if (permissions !== 'admin') {
          alert("Permissão inválida para acessar esta página")
          return next(false)
        }
      }
  }
    
  next()
})


export default router
