<template>
  <main>
    <div v-show="isLoading" class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center">
      <SpinnerComponent />
    </div>
    <div class="flex justify-center border-t border-gray-300">
      <ul v-if="this.total != 0" class="inline-block relative pagination text-sm p-0 m-0">
        
          <li v-if="this.page != 1" class="absolute right-48">
            <a href="#" @click="this.getData(this.page-1)" class="flex justify-center items-center text-sm">
              <svg class="h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
              Anterior
            </a>
          </li>
        
          <li v-for="num in numbers" :key="num" class="inline">
            <a href="#" v-if="num == this.page" @click="this.getData(num)" class="active">{{ num }}</a>
            <a href="#" v-else @click="this.getData(num)">{{ num }}</a>
          </li>
      
          <li v-if="this.total != this.page" class="absolute left-48">
            <a @click="this.getData(this.page+1)" href="#" class="flex justify-center items-center text-sm" >
              Próximo 
              <svg class="h-3 w-3 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" /></svg>
            </a>
          </li>
      </ul>
    </div>
  </main>
</template>

<script>
import SpinnerComponent from '@/components/helper/SpinnerComponent.vue';

  export default {
    name: "PaginationComponent",
    components: { SpinnerComponent },
    props: {
      projeto: {
        type: String,
        required:true
      },
      limit: {
        type: Number,
        required: false,
        default: 10
      },
      busca: {
        type: String,
        required: false
      }
    },
    data(){
      return{
        page: 1,
        total: null,
        numbers: [],
        isLoading: false
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      async getData(page_solicited = null) {    
        this.isLoading = true;

        var init = 0

        if(page_solicited){
          init = this.page =  page_solicited
          init = init -1
        }

        if(this.page > 1){
          init = init * this.limit
        }

        var body = {
          page : init,
          size : this.limit,
          busca : this.busca
        }
        const req_aulas = await fetch(`${process.env.VUE_APP_URL}/aulas/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'DataBase': this.projeto
            },
            body: JSON.stringify(body)
        });
        const data = await req_aulas.json();
        
        this.$emit('emit_data', data.aulas);
        this.total = Math.ceil(data.total/this.limit);
                
        this.getNumberPage()

        this.isLoading = false;
      },

      getNumberPage(){
        if(this.total <= 5){
          var num_pages = [];
          for (var i = 1; i <= this.total; i++) {
            num_pages.push(i)  
          }
          this.numbers = num_pages
        }else{
          this.numbers = [ 1, 2, 3, 4, 5 ]

          if(this.page > 3){
            this.numbers = [ this.page-2, this.page-1, this.page, this.page+1, this.page+2 ]
            
            if(this.page == this.total - 1 ){
              this.numbers = [ this.page-2, this.page-1, this.page, this.page+1 ]
            }
            if(this.page == this.total ){
              this.numbers = [ this.page-2, this.page-1, this.page ]
            }
          }
        }
      },
    },
    watch: {
      async busca() {
        var body = {
          page : 0,
          size : this.limit,
          busca : this.busca
        }
        const req_aulas = await fetch(`${process.env.VUE_APP_URL}/aulas/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'DataBase': this.projeto
            },
            body: JSON.stringify(body)
        });
        const data = await req_aulas.json();

        this.$emit('emit_data', data.aulas);
        this.total = Math.ceil(data.total/this.limit);

        this.page = 1
        this.getNumberPage()
      },
      async projeto() {
        this.isLoading = true;

        var body = {
          page : 0,
          size : this.limit,
          busca : this.busca
        }
        const req_aulas = await fetch(`${process.env.VUE_APP_URL}/aulas/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'DataBase': this.projeto
            },
            body: JSON.stringify(body)
        });
        const data = await req_aulas.json();

        this.$emit('emit_data', data.aulas);
        this.total = Math.ceil(data.total/this.limit);

        this.page = 1
        this.getNumberPage()
        this.isLoading = false;
      }
    }
  }
</script>

<style scoped>
  ul.pagination li a {
    color: black;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }

  ul.pagination li a.active {
    border-top: 1px solid rgb(88, 88, 88);
    margin-top: -1px;
    color: rgb(0, 0, 0);
  }

  ul.pagination li a:hover:not(.active) {
    border-top: 1px solid rgb(88, 88, 88);
    margin-top: -1px;
  }
</style>