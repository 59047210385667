<template>
  <header class="bg-white border-b border-gray-300 shadow-lg">
    <div class="flex items-center h-16 px-4 mx-auto max-w-full gap-8 sm:px-6 lg:px-8">
      <router-link v-if=" getProjects().length == 1" :to="`/${getProjects()[0].type}/${getProjects()[0].db}`">
        <img alt="Vue logo" src="../../public/img/logo.png" class="h-10">
      </router-link>
      <router-link v-else to="/">
        <img alt="Vue logo" src="../../public/img/logo.png" class="h-10">
      </router-link>
      <div class="flex items-center justify-end flex-1 md:justify-between">
        <nav class="hidden md:block" aria-labelledby="header-navigation">
          <h2 class="sr-only" id="header-navigation">Header navigation</h2>
          <ul class="flex items-center text-sm gap-6">
            <li>
              <router-link v-if=" getProjects().length < 1" to="/" class="font-bold px-3 py-1.5 border-gray-200 border rounded-sm">
                Projetos
              </router-link>
            </li>
            <!-- NavBar Desktop -->
            <li v-for="project in getProjects()" :key="project.id">
              <router-link :to="{ name: project.type, params:{  projeto: project.db  }}">
                {{  project.name }}
              </router-link>
            </li>
          </ul>
        </nav>
        
        <div class="flex items-center gap-4">
          <div  class="sm:gap-4 sm:flex sm:items-center sm:justify-center hidden md:flex">
            <div class="flex items-center justify-center w-8 h-8 bg-gray-100 object-cover rounded-lg aspect-square ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-sky-800">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
            </div>

            <p class="hidden sm:block px- py-2.5 whitespace-nowrap text-sm font-medium text-sky-600 hover:text-sky-600/75 transition">
              {{  nome }}
            </p>
            
            <a @click="LOG_USER_OUT()" href="javascript:void(0);" class="block sm:px-5 sm:py-2.5 px-2 py-1 text-sm font-medium text-white bg-sky-600/75 hover:bg-sky-700 transition">
              Sair
            </a>
          </div>
          <!-- Vimeo Dropdown Hambúrguer -->
            <div class="group relative" >
              <button @click="showDropdownNav = !showDropdownNav" class="group relative block p-2.5 text-gray-600 transition bg-gray-100 md:hidden hover:text-gray-600/75">
                <span class="sr-only">Toggle menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
                <div  @mouseleave="showDropdownNav = 0" v-show="showDropdownNav"  class="hidden group-hover:block delay-100 absolute z-10 right-10 bg-white divide-y divide-gray-100 shadow-xl border border-gray-300">
                  <ul class="w-42 space-y-1 p-3 text-sm text-gray-700" aria-labelledby="dropdownRadioButton">
                      <li  class="flex  mx-3 items-center justify-around">
                        <div class="flex items-center justify-center w-8 h-8 bg-gray-100 object-cover rounded-lg aspect-square ">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-sky-800">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                              </svg>
                            </div>

                            <p class=" py-2.5 whitespace-nowrap text-sm mx-8 font-medium text-sky-600 hover:text-sky-600/75 transition">
                            {{  nome }}
                            </p>

                            <a @click="LOG_USER_OUT()" href="javascript:void(0);" class="block sm:px-5 sm:py-2.5 px-2 py-1 text-sm font-medium text-white bg-sky-600/75 hover:bg-sky-700 transition">
                              Sair
                            </a>
                      </li>
                      <li class="text-left m-5 py-2">
                        <router-link to="/">
                          Projetos
                        </router-link>
                      </li>
                      <li v-for="project in getProjects()" :key="project.id" class="text-left m-5 py-2">
                        <router-link :to="{ name: project.type, params:{  projeto: project.db  }}">
                          {{  project.name }}
                        </router-link>
                      </li>
                    
                  </ul>
                </div>
            </div>
          <!-- FIM Vimeo Dropdown Hambúrguer-->
        </div>
      </div>
    </div>
  </header>
</template>

<script>

  import { mapGetters, mapMutations } from 'vuex'
  export default {
    name: "NavbarComponent",
    
    data() {
      return {
        showDropdownNav: 1,
        user: {
          firstName: '',
        },
        nome: "",
        projetos: null
      }
    },
    methods:{
      ...mapMutations(['LOG_USER_OUT']),
      ...mapGetters(['getUser','getUserName', 'getProjects', 'getLoadingProject', 'getisAuthenticated']),
    },
    mounted() {
      this.nome = this.getUserName()
      // this.user = this.getUser()
    },
  }
</script>

<style scoped>
  nav {
    padding: 30px;
  }

  nav a:hover {
    color: rgb(2 132 199 / 0.75);
  }

  nav a {
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    color: rgb(2 132 199);
  }

  nav a.router-link-exact-active {
    color:  rgb(0, 61, 91);
    font-weight: 600;
}
</style>
