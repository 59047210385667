<template>
  <main>
    <div class="flex h-screen" v-if="isLoading">
        <SpinnerComponent class="m-auto" />
    </div>
    <div v-else>
      <div class="flex flex-col md:mt-6">
        <div class="overflow-x-auto sm:my-2 lg:mx-8">
          <div class="inline-block min-w-full sm:py-6 align-middle md:px-6 ">
            <div class="overflow-hidden border-b border-gray-200 shadow-md bg-gray-50">
              <div class="flex justify-between items-center m-6">
                  <div class="items-start text-start">
                    <h1 class="text-2xl mr-3 text-gray-500 capitalize">Importar  {{ nome_Tabela }}</h1>
                    <router-link to="/" class="flex items-center justify-start mt-3 text-sky-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1 -rotate-90">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                      <span class="text-sm font-medium"> Voltar </span>
                    </router-link>
                  </div>
                  <div class="flex"> 
                    <button @click="exportarTabela()" class="flex mr-3 justify-center bg-transparent hover:bg-sky-500 text-sky-500 hover:text-white py-2 px-4 border border-sky-500 hover:border-transparent">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>
                      Baixar
                    </button>
                    <button id="btn_import" @click="get_btn_Importador()" class="flex justify-center bg-transparent hover:bg-sky-500 text-sky-500 hover:text-white py-2 px-4 border border-sky-500 hover:border-transparent">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                      Importar dados
                    </button>
                  </div>
              </div>
              <table id="table_new_dados" class="min-w-full overflow-x-scroll divide-y divide-gray-200">
                <thead class="bg-gray-100">
                    <tr>
                        <th class="whitespace-nowrap px-4 py-7 text-left font-medium text-gray-900"></th>
                        <th v-for="(coluna, index) in colunas" :key="index" class="px-4 py-2 text-left font-normal text-gray-900">
                          <div class="flex items-center whitespace-nowrap capitalize">
                            <a v-if="coluna.Key == 'MUL'" href="javascript:void(0);">
                              {{ coluna.Field }}
                              <div class="relative" @click="showDropdown[index] = !showDropdown[index]" >
                                <p  @click="open_modal_Relacionar(coluna)"  class="flex absolute text-[0.6rem] py-0.2 px-1 text-gray-800 hover:bg-gray-300">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                  </svg>
                                  Relacionar
                                </p>
                              </div>
                            </a>
                            <span v-else>
                              {{ coluna.Field }} 
                            </span>
                            <p v-if="coluna.Null == 'NO'" class="text-[0.6rem] py-0.5 px-1 ml-2 bg-slate-200 text-gray-800">✱</p>
                            <p v-if="coluna.Key == 'UNI'" class="text-[0.6rem] py-0.5 px-2 ml-2 bg-orange-600 text-gray-50">Único</p>
                            <p v-if="coluna.Type.toUpperCase().indexOf('INT') !== -1 " class="text-[0.6rem] py-0.5 px-2 ml-3 bg-violet-800 text-gray-50">123</p>
                          </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 border-t">
                  <tr v-for="(dado, index_dado) in dados" :key="index_dado" class="transition-all hover:bg-gray-100 hover:shadow-lg"  @mouseleave="showDelete[index_dado] = 0"  @mouseover="showDelete[index_dado] = 1" >
                    <td class="justify-center items-center bg-slate-100"><input class="h-3.5 w-3.5 border-gray-400" type="checkbox" id="Row1" checked disabled/></td>
                    <td v-for="(coluna, index_coluna) in colunas" :key="index_coluna" class="whitespace-nowrap p-2 font-medium text-gray-900">
                      <div class="relative">
                        <input 
                          v-model="dado[coluna[Object.keys(coluna)[0]]]"
                          @click="inputHandler(index_dado, coluna[Object.keys(coluna)[0]])"
                          @input="inputHandler(index_dado, coluna[Object.keys(coluna)[0]])"
                          :id="index_dado+'_'+index_coluna"
                          :placeholder="dado_placeholder(coluna)" 
                          class="w-full p-2 text-sm border border-gray-200 focus:outline-none focus:border-blue-400 focus:bg-white pr-8" 
                          @change="validarElemento(index_dado+'_'+index_coluna, dado, coluna)" 
                          @keydown.enter.stop.prevent 
                          autocomplete="off">

                          <ul v-if="coluna.Key == 'MUL'"  v-show="celula.linha == index_dado && celula.nome_coluna == coluna.Field &&  op_coluna_externa.length > 0"
                            class="fixed h-max-1/3 w-1/4 overflow-y-scroll z-10 bg-white border text-left shadow-md py-2">
                            <li v-for="(op, index_op) in op_coluna_externa" :key="index_op" @click="selectOption(index_dado, coluna.Field, op.value)"
                              class="hover:bg-gray-200 px-4 py-1 cursor-pointer whitespace-normal">
                                {{op.option}}
                                <br>
                                <span class="text-xs ml-1 border px-3 py-0.5 rounded-full bg-slate-500/50 text-white">
                                  {{op.value}}
                                </span>
                            </li>
                          </ul>
                        
                        <button v-if="coluna.Field.toUpperCase().indexOf('DISCIPLINA') !== -1" 
                          @click="SHOW_MODAL_DISCIPLINA(true), SET_NEW_DISCIPINA(dado[coluna[Object.keys(coluna)[0]]])"
                          :id="'options_'+index_coluna+'_'+index_dado" class="absolute right-2.5 text-gray-400 hover:text-gray-800 text-sm pr-4 py-2 bg-transparent">
                          <svg class="w-5 h-5 ml-2 -mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                        </button>
                        <button v-if="( (coluna.Field.toUpperCase().indexOf('AUTOR') !== -1) || (coluna.Field.toUpperCase().indexOf('PALESTRANTE') !== -1) )" 
                          @click="SHOW_MODAL_AUTORES(true), SET_NEW_AUTOR(dado[coluna[Object.keys(coluna)[0]]])"
                          :id="'options_'+index_coluna+'_'+index_dado" class="absolute right-2.5 text-gray-400 hover:text-gray-800 text-sm pr-4 py-2 bg-transparent">
                          <svg class="w-5 h-5 ml-2 -mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                        </button>

                      </div>
                    </td>
                    <Transition>
                      <td v-show="showDelete[index_dado]" class="justify-center items-center bg-gray-50/50 absolute border mt-2">
                        <div class=" flex items-center right-[-148px] top-[300px]">
                            <a @click="removerRow(index_dado)" href="javascript:void(0);" class="flex items-center border-l-[2px] border-transparent px-2 py-3  text-gray-500 hover:border-gray-300 hover:bg-white  hover:text-gray-700">
                              <svg class="w-4 h-4 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
                            </a>
                        </div>
                      </td>
                    </Transition>
                  </tr>
                </tbody>
              </table>
            </div>
              <button @click="adicionarRow()" class="block text-[0.7rem] px-2 py-1 border text-gray-700 bg-slate-100 hover:bg-gray-50 focus:relative">
                + Nova Linha
              </button>
          </div>
        </div>
        <div class="mr-14 text-end mt-2 mb-8">
          <a @click="btn_gravar()" href="javascript:void(0)" class="bg-white w-60 transition-shadow hover:shadow-lg  border border-gray-200 px-12 py-3 text-sky-600 hover:bg-sky-500 hover:text-white focus:outline-none focus:ring active:bg-sky-500">
            <div class="inline-flex items-center ">
              <p class="text-md font-medium">Gravar</p>
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-6 w-6 transform transition-transform group-hover:translate-x-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
            </div>
          </a>
        </div>

        <Modal_New_Autor v-show="getShowModalNewAutor()" class="relative" :projeto="projeto"/>
        <Modal_New_Disciplina v-show="getShowModalnewDisciplina()" :projeto="projeto"/>

        <Modal_Relacionar @emit_data="getDadosRelacionados" :open_relacionar="isOpenRelacionar" @close_modal_relacionar="isOpenRelacionar = !this.close_modal_relacionar(isOpenRelacionar)" :projeto="projeto" :coluna_prop="coluna_interna_relacionar" />
        <Modal_Confirmacao @emit_data="EmitConfirmacao" :open_confirmacao="isOpenConfirmacao" @close_confirmacao="isOpenConfirmacao = !this.close_confirmacao(isOpenConfirmacao)" />
      </div>
    </div>
  </main>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex'
  import { ref } from "vue";
  import SpinnerComponent from '@/components/helper/SpinnerComponent.vue'
  import Modal_Relacionar from "@/components/Modal/Modal_Relacionar.vue";
  import Modal_New_Disciplina from "@/components/Modal/Modal_New_Disciplina.vue";
  import Modal_New_Autor from "@/components/Modal/Modal_New_Autor.vue";
  import Modal_Confirmacao from "./Modal/Modal_Confirmacao.vue";
  import debounce from 'lodash/debounce';
  import FlatfileImporter from '@flatfile/adapter';
  
  export default {
    name: "ImportadorComponent",
    props: {
        nome_Tabela: {
          type: String,
          required: true
        },
        projeto: {
          type: String,
          required: true
        },
    },
    components: { SpinnerComponent, Modal_Relacionar, Modal_New_Disciplina, Modal_New_Autor, Modal_Confirmacao },
    setup() {
        const isOpen = ref(false);
        const isOpenRelacionar = ref(false);
        const isOpenConfirmacao = ref(false);
      
        return ({ isOpen, isOpenRelacionar, isOpenConfirmacao });
    },
    data() {
      return {
        row: null,
        colunas: null,
        coluna: null,
        dados: null,
        placeholder: null,

        coluna_interna_relacionar: 1,
        
        showDropdown: [],
        showDelete: [],

        op_coluna_externa: [],

        erro_validacao: false,

        msg: "",
        success: null,
        isLoading: false,

        dado_modal: null,
        dado_coluna: null,
        dado_linha: null,

        obj_relacionar: {},
        celula: {
          linha: null,
          coluna: null
        }
      };
    },
    methods: {
      ...mapGetters(['getShowModalNewAutor', 'getShowModalnewDisciplina']),
      ...mapMutations(['SET_ALERT', 'SHOW_MODAL_AUTORES', 'SET_NEW_AUTOR', 'SHOW_MODAL_DISCIPLINA', 'SET_NEW_DISCIPINA']),

      saveStorage() {
        localStorage.setItem(`date_${this.projeto}+${this.nome_Tabela}`, JSON.stringify(new Date()));
        localStorage.setItem(`dados_${this.projeto}+${this.nome_Tabela}`, JSON.stringify(this.dados));
      },
      async validarInput(){
        this.saveStorage();
        
        this.colunas.forEach((coluna, index_coluna) => {
          this.dados.forEach((dado, index_linha) => {
            this.validarElemento(index_linha+'_'+index_coluna, dado, coluna)
          });
        });
        
      },
      async validarElemento(id_el, dado, coluna){
        let el = document.getElementById([id_el]);

        if(coluna.Field.toUpperCase().indexOf("EMAIL") !== -1 || coluna.Field.toUpperCase().indexOf("E-MAIL") !== -1 ) {
            var regexEmail = /\S+@\S+\.\S+/;
            if(!regexEmail.test(dado[coluna.Field])) { 
              el.style.borderColor = "red";
            }else  el.style.borderColor = "green";
          }
          if(coluna.Null.toUpperCase() == "NO") {   
            if((dado[coluna.Field] == null) || (dado[coluna.Field] == '')) { 
              el.style.borderColor = "red";

            } else  el.style.borderColor = "green";
          }
          if((coluna.Key.toUpperCase() == "PRI") || (coluna.Type.toUpperCase().indexOf("INT") !== -1 )) {
            var regexNumber = /^[0-9]+$/;
            if(!regexNumber.test(dado[coluna.Field])) { 
              el.style.borderColor = "red";
            }else  el.style.borderColor = "green";
          }  
      },
      async getDadosRelacionados( dados_externo, relacionar, subistituir, tabela_relacionada ) {
        const data_relacionar = { subistituir, tabela_relacionada, relacionar }
        this.obj_relacionar = data_relacionar;
        localStorage.setItem(`data_relacionar_${this.projeto}+${this.nome_Tabela}`, JSON.stringify(data_relacionar));

        this.dados.forEach(dado => {
          var dado_interno = dado[(this.coluna_interna_relacionar.Field)].toString();
          dado_interno = dado_interno.toLowerCase().trim();

          dados_externo.forEach(dado_externo => {
            var dado_ext = dado_externo[relacionar].toString();
            dado_ext = dado_ext.toLowerCase().trim();

            if(dado_interno == dado_ext) {
              dado[(this.coluna_interna_relacionar.Field)] = dado_externo[subistituir];
            }
          });
        });
        this.validarInput();
      },
      async open_modal_Relacionar(coluna) {
          this.coluna_interna_relacionar = coluna;
          this.isOpenRelacionar = true
      },
      async close_modal_relacionar(isOpenRelacionar) {
          return isOpenRelacionar;
      },
      async close_confirmacao(isOpenConfirmacao) {
          return isOpenConfirmacao;
      },
      async EmitConfirmacao( resposta_confirmacao ) {
        this.close_confirmacao(this.isOpenConfirmacao = !this.close_confirmacao(this.isOpenConfirmacao))

        if(resposta_confirmacao) {
          this.isLoading = true;
          // Camar a confirmação para o inserte
          
          const req_insert = await fetch(`${process.env.VUE_APP_URL}/row/add/${this.nome_Tabela}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'DataBase': this.projeto
                    },
                    body: JSON.stringify(this.dados)
                });
          
          const response = await req_insert.json();
          
          if(Object.keys(response) == "message") {
            this.SET_ALERT({ heading: 'success', message: response.message });
              setTimeout(() => {
                this.$router.push('/');
                localStorage.removeItem(`dados_${this.projeto}+${this.nome_Tabela}`);
              }, 5000);
          } else {
            this.SET_ALERT({ heading: 'erro', message: response.error });
          }
          this.isLoading = false;
        } 
      },
      async btn_gravar(){
        this.validarInput();
        this.isOpenConfirmacao = true;
      },
      exportarTabela(){
        this.isLoading = true;
        var tabela = '<table><thead><tr>';

        this.colunas.forEach(coluna => {
          tabela += '<th> ' + coluna.Field.toUpperCase() + ' </th>'
        });

        tabela += '</tr>'
        this.dados.forEach(dado => {
          tabela += '<tr>'
          for (let index = 0; index < Object.keys(dado).length; index++) {
            tabela += '<th style="font-weight: normal;"> ' + dado[Object.keys(dado)[index]] + '</th>'
          }
          tabela += '</tr>'
        });
        tabela += '</thead></table>'
        var Dados = new Blob(['\ufeff' + tabela], {type:'application/vnd.ms-excel'});
        var url = window.URL.createObjectURL(Dados);
        var a = document.createElement('a');

        a.href = url;
        var d = new Date();

        a.download = `Dados [ ${d.getDate()}_${d.getMonth()}_${d.getFullYear()} ${d.getHours()}h${d.getMinutes()} ]`;

        a.click();
        this.isLoading = false;
      },

      dado_placeholder(coluna){
        if((this.placeholder == null) || (!this.placeholder) || (this.placeholder.length == 0)) return null
      
        const placeholder = this.placeholder[0]; 
        
        if( placeholder[coluna.Field] ) {
          return placeholder[coluna.Field];
        }
      },
      
      async configurando_Importador() {
        this.importer = new FlatfileImporter("b954896f-b7fb-464c-a988-bfecf093b01b", {
          type: "imports",
          fields: await this.getColunasImportador(),
          managed: true,
        }),
        this.importer.setCustomer({userId: "12345"});
      },

      async get_btn_Importador() {
        var dados_importados = await this.importer.requestDataFromUser()
        await this.importer.displaySuccess('Dados publicados com sucesso !')

        this.dados = dados_importados.data;
      },

      async getColunasImportador() {
        const colunas = this.colunas;
        let fields = [];
        colunas.forEach(coluna => {
          var response_validaddor = this.gerar_validador(coluna)
          if(response_validaddor !== false) {
            fields.push(response_validaddor)
          } 
        });

        return fields;
      },

      gerar_validador(coluna) {
        var restricoes = [];
        //if(coluna.Extra == "auto_increment") return false;  // Culunas do ID com autocomplete

        if(coluna.Key.toUpperCase() == "PRI") // Coluna com Chave primaria
          return {key: coluna.Field, label: coluna.Field, validators: [{ validate: "regex_matches", regex: "^[0-9]+$", error: "Somente números."}, { validate: "unique" }]}
        
        if(coluna.Field.toUpperCase().indexOf("EMAIL") !== -1 || coluna.Field.toUpperCase().indexOf("E-MAIL") !== -1 )  // Coluna com nome de Email
          return {key: coluna.Field, label: coluna.Field,   validators: [{ validate: "regex_matches", regex: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", error: "E-mail inválido"}, {validate: "required"}, {validate: "unique"}]};

        if(coluna.Null.toUpperCase() == "NO") {  // Culunas não aceita nulo
          restricoes.push({validate: "required"});
        }

        if(restricoes !== []){
          return {key: coluna.Field, label: coluna.Field, validators: restricoes}
        }

        return true
      },
      adicionarRow() {
        var col_json = []
        this.colunas.forEach(col => {
          var coluna = col.Field
          col_json.push('"' + coluna +'": null')
        });
        
        if(this.dados == null) this.dados = [JSON.parse('{'+ col_json.join(',') +'}')];
        else this.dados.push(JSON.parse('{'+ col_json.join(',') +'}'))
      },
      removerRow(indexRow) {
        this.dados.splice(indexRow, 1)
      },
      async getEstruturaTabela() {
        this.isLoading = true;
        const req_tables = await fetch(`${process.env.VUE_APP_URL}/tabela/${this.nome_Tabela}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Database' : this.projeto
          },
        });
        this.colunas = await req_tables.json();
        this.colunas.forEach((coluna, index) => {
          if(coluna.Extra == "auto_increment") 
            this.colunas.splice(index, 1);
        });
        
        if(this.dados == null) {
          this.adicionarRow();
        }
        
        const req_placeholder = await fetch(`${process.env.VUE_APP_URL}/row/${this.nome_Tabela}?page=1&limit=1`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Database" : this.projeto
          },
        });
      
        this.placeholder = await req_placeholder.json();
        this.configurando_Importador(); 

        this.isLoading = false;
      },

      async buscarDadosRelacionados(linha, nome_coluna) {
        if(this.obj_relacionar == null)             return;
        if(!isNaN(this.dados[linha][nome_coluna]))  return;
        
        var dado = this.dados[linha][nome_coluna].toString();

        var body = {
          obj_relacionar: this.obj_relacionar,
          busca: dado.toLowerCase().trim()
        }
        const req = await fetch(`${process.env.VUE_APP_URL}/row/${nome_coluna}/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Database" : this.projeto
                    },
                    body: JSON.stringify(body)
                });
          await req.json().then((res) => {
            this.op_coluna_externa = res;
          return;
        }).catch((error) => {
            console.log("error_msg", "Houve um erro:" + error);
            this.isLoading = false;
        });
        this.isLoading = false;
      },

      inputHandler: debounce(async function(linha, nome_coluna) {
        this.celula = {linha, nome_coluna};
         await this.buscarDadosRelacionados(linha, nome_coluna);
      }, 500),

      selectOption(linha, nome_coluna, value){
        this.dados[linha][nome_coluna] = value;
        this.op_coluna_externa.length = 0;
        this.saveStorage();
      }

    },

    mounted() {
      this.getEstruturaTabela();
      
      const KeyLocalStorage = `dados_${this.projeto}+${this.nome_Tabela}`;
      
      if(localStorage.getItem(KeyLocalStorage) ) {
        const atualDate = new Date();
        const storageDate = new Date( JSON.parse(localStorage.getItem(`date_${this.projeto}+${this.nome_Tabela}`)));

        storageDate.setHours(storageDate.getHours() + 12);

        if (storageDate < atualDate) {
          localStorage.removeItem(`date_${this.projeto}+${this.nome_Tabela}`);
          localStorage.removeItem(`dados_${this.projeto}+${this.nome_Tabela}`);
          localStorage.removeItem(`data_relacionar_${this.projeto}+${this.nome_Tabela}`);
        } else {
          this.dados = JSON.parse(localStorage.getItem(KeyLocalStorage))
          this.obj_relacionar = JSON.parse(localStorage.getItem(`data_relacionar_${this.projeto}+${this.nome_Tabela}`))
        }
      } 
     
    }
  }
</script>
