<template>
  <Navbar />
  <div class="main-container">
    <Importador :nome_Tabela="String($route.params.tabela)" :projeto="String($route.params.projeto)" />
  </div>
</template>

<script>
import Importador from '@/components/Importador.vue';

import Navbar from '@/components/Navbar.vue'

  export default {
    data(){
      return {
      }
    },
    components: { 
      Navbar,
      Importador
     }
  }  
</script>
