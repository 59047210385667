<template>
  <div class="max-w-5xl p-4 mx-auto">
    <section class="flex relative bg-white border border-gray-200  hover:border-gray-400  hover:ring-gray-200 shadow-md rounded-xl">
        <div class="grid grid-cols-1 gap-12 sm:grid-cols-4 items-center p-6">
          <div class="block items-center justify-center">  
            <router-link :to="{ name: type, params:{  projeto:  String(db) }}">
              <img alt="Man" :src="img" class="max-w-full h-auto">
              <p class="whitespace-nowrap text-xs mt-2 py-1 border rounded text-gray-400 leading-4 hover:text-gray-600 hover:border-gray-400 hover:bg-slate-50">
                Clique aqui para <br>visualizar as Aulas 
              </p>
            </router-link>
          </div>
          <div class="sm:col-span-3 text-left">
            <p class="font-medium">
              {{ descricao }}
            </p>

            <span class="inline-flex items-center mt-8 not-italic">
              <p class="text-sm text-gray-500 sm:ml-3">
                <span v-html="projeto_name"></span>
              </p>
            </span>
          </div>
        </div>
      <div class="absolute right-0 bottom-0">
        <a href="javascript:void(0);" class="" @click="getTabela()" >
          <strong class="mb-[-2px] inline-flex items-center gap-1 rounded-tl-xl rounded-br-xl bg-gray-500 py-1.5 px-3 text-white  border-gray-200  hover:bg-sky-700 whitespace-nowrap">
            <span :class="{ '-rotate-180': activeAccordion === 1 }" class="transition" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 rotate-180">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </span>
              <span class="text-[10px] font-medium sm:text-xs">Importar Dados</span>
          </strong>
        </a>
      </div>
    </section>
    <Transition>
      <div v-show="activeAccordion === 1" x-collapse class="mx-5 bg-gray-200 border border-gray-200 hover:ring-gray-200">
          <div class="mx-auto max-w-screen-xl px-8 py-4">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
                <div class="text-start">
                  <p class="font-medium flex whitespace-nowrap">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                    </svg>
                    Banco de Dados
                  </p>
                  <div class="mt-4 flex flex-col ml-4 space-y-2 text-sm text-gray-500">
                    <router-link  v-for="(tabela, index) in tabelas" :key="index" :to="{ name: 'importador', params:{  projeto: String(db),  tabela: String(tabela.name)}}" class="hover:text-sky-700 flex whitespace-nowrap" @mouseover="getColunas(index)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1 w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                        </svg>
                        Tabela <span class=" ml-2"> {{tabela.name}}</span>
                    </router-link>
                  </div>
                </div>
                <div>
                  <div v-show="showColunas" @mouseover="showColunas = true" class="pt-4 text-sm">
                    <ul class="p-6 text-gray-400 bg-gray-100 text-start space-y-1">
                      <li class="text-gray-500 mb-3">
                        <div class="flex">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                          </svg>
                          <span class="font-bold whitespace-nowrap capitalize">Tabela {{ nomeTabela }}</span>
                        </div>
                        <p class="font-medium ml-4 mt-2"> Colunas </p>
                      </li>
                      <li v-for="(coluna, index) in columns" :key="index" class="flex whitespace-nowrap capitalize ml-4">
                        {{coluna.Field}}
                        <p v-if="coluna.Key == 'MUL'" class="text-[0.7rem] py-0.5 px-2 ml-3 bg-sky-600 text-gray-50 bg-sky-600/75 hover:bg-sky-700 transition">
                          <router-link :to="{ name: 'importador', params:{ projeto: String(db), tabela: String(relacionando_Key_Mul(coluna.Field))}}" >
                            Relacionada
                          </router-link>
                        </p>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </Transition>
  </div>
</template>

<script>

export default {
name: 'List_Projetos',
props: {
        projeto_name: {
          type: String,
          required:true
        },
        img: {
          type: String,
          required:true
        },
        descricao: {
          type: String,
          required:true
        },
        type: {
          type: String,
          required:true,
        },
        db: {
          type: String,
          required:true
        },
    },
data() {
  return {
    tabelas: null,
    tabela: null,
    columns: null,
    coluna: null,
    nomeTabela: null,
    coluna_relacionada: null,
    activeAccordion: 0,
    showColunas: false,
  };
},
  methods: {
    relacionando_Key_Mul(coluna){
      var link = null;
      this.tabelas.forEach(tabela => {
        if(coluna.toUpperCase().indexOf(tabela.name.substring(0, tabela.name.length - 1).toUpperCase()) !== -1 ) {
          link = tabela.name;
        }
      });
      return link;
    },
    async getTabela() {
      this.activeAccordion = this.activeAccordion === 1 ? false : 1 ;

      if(this.tabelas === null) {  
        this.tabelas = null
        const req_tables = await fetch( `${process.env.VUE_APP_URL}/tabela/`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Database' : this.db
          },
        });

        const tables = await req_tables.json();

        this.tabelas = tables.data;
      }
    },
    async getColunas(index) {
        const tabela = this.tabelas[Number(index)];

        this.columns = tabela.colunas;
        this.nomeTabela = tabela.name
        this.showColunas = true;
    },
  },
  mounted() {
    // this.getTabela();
  }
}
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>