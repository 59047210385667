<template>
  <div class="flex flex-col md:mt-6">
    <div class="overflow-x-auto sm:my-2 lg:mx-8">
      <div class="inline-block min-w-full sm:py-6 align-middle md:px-6 ">
        <div class="overflow-hidden border-b border-gray-200 shadow-md bg-gray-50">
          <div class="flex justify-between items-center m-8">
            <div class="flex items-center justify-between">
                <ol class="inline-flex items-center space-x-1 md:space-x-3 ">
                  <li class="inline-flex items-center">
                    <router-link to="/" class="inline-flex items-center md:text-2xl font-medium capitalize text-gray-600 hover:text-gray-900">
                      {{ projeto }}
                    </router-link>
                  </li>
                  <li>
                    <div class="flex items-center md:text-2xl font-medium text-gray-500 hover:text-gray-900">
                      <svg class="w-6 h-6 text-gray-400 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        Aulas
                    </div>
                  </li>
                  <li>
                    <router-link :to="{ name: 'form_aula', params:{ projeto: projeto}}" class="border border-sky-500 text-sky-500 ml-3 px-3 py-2 text-sm bg-white hover:text-sky-600 hover:border-sky-600 justify-center whitespace-nowrap">
                        + Nova Aula
                    </router-link>
                  </li>
                </ol>
            </div>
            <div class="flex items-center justify-between">
            <div class="mr-4 hidden md:block">
                <div class="group relative">
                    <!-- <button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio"
                      class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium text-sm px-3 py-1.5"
                      type="button">
                      <svg class="mr-2 w-4 h-4 text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                      Últimos 30 dias
                      <svg class="ml-2 w-3 h-3" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    </button> -->
                  <div id="dropdownRadio"
                    class="hidden group-hover:block delay-100 absolute z-10 w-[10.45rem] bg-white rounded divide-y divide-gray-100 shadow-xl border border-gray-200"
                    style="transform: translate(0px, 0px);" data-popper-reference-hidden="" data-popper-escaped=""
                    data-popper-placement="">
                      <ul class="space-y-1 text-sm text-gray-700" aria-labelledby="dropdownRadioButton">
                          <li>
                              <div class="flex items-center p-2 hover:bg-gray-100">
                                  <input v-model="periodo" v-on:change="this.filter_aula()" id="periodo_1" type="radio" value="1 DAY" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
                                  <label for="periodo_1" class="text-left ml-2 w-full text-sm font-medium text-gray-900">Último dia</label>
                              </div>
                          </li>
                          <li>
                              <div class="flex items-center p-2 hover:bg-gray-100">
                                  <input v-model="periodo" v-on:change="this.filter_aula()" id="periodo_2" type="radio" value="7 DAY" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
                                  <label for="periodo_2" class="text-left ml-2 w-full text-sm font-medium text-gray-900">Últimos 7 dias</label>
                              </div>
                          </li>
                          <li>
                              <div class="flex items-center p-2 hover:bg-gray-100">
                                  <input v-model="periodo" v-on:change="this.filter_aula()" id="periodo_3" type="radio" value="30 DAY" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" checked>
                                  <label for="periodo_3" class="text-left ml-2 w-full text-sm font-medium text-gray-900">Últimos 30 dias</label>
                              </div>
                          </li>
                          <li>
                              <div class="flex items-center p-2 hover:bg-gray-100">
                                  <input v-model="periodo" v-on:change="this.filter_aula()" id="periodo_4" type="radio" value="MONTH" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
                                  <label for="periodo_4" class="text-left ml-2 w-full text-sm font-medium text-gray-900">Últimos mês</label>
                              </div>
                          </li>
                          <li>
                              <div class="flex items-center p-2 hover:bg-gray-100">
                                  <input v-model="periodo" v-on:change="this.filter_aula()" id="periodo_5" type="radio" value="YEAR" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
                                  <label for="periodo_5" class="text-left ml-2 w-full text-sm font-medium text-gray-900">Últimos ano</label>
                              </div>
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
              <label for="table-search" class="sr-only">Search</label>
              <div class="relative">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="busca" type="text" id="table-search"
                  class="block p-1.5 pl-10 w-36 sm:w-80 text-sm text-gray-900 bg-white  border border-gray-200 focus:outline-none focus:border-blue-400"
                  placeholder="Busca por Aula, Disciplina">
              </div>
            </div>
          </div>
          <table class="min-w-full overflow-x-scroll divide-y divide-gray-200">
            <tbody class="bg-white divide-y divide-gray-200 border-t">
              <tr v-for="aula in aulas" :key="aula.id" class="transition-all hover:bg-gray-100 hover:shadow-lg">
                <td class="pl-6 whitespace-nowrap">
                    <!-- Vimeo Dropdown -->
                    <div class="group relative">
                      <a class="cursor-pointer items-center">
                          <svg class="mr-3 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" /></svg>
                      </a>
                      <div class="hidden group-hover:block delay-100 absolute z-10  bg-white rounded divide-y divide-gray-100 shadow-xl border border-gray-300">
                        <div class="py-2 px-3 rounded-t-lg border-b border-gray-300 ">
                            <h3 class="font-semibold text-gray-600">Link do Vimeo </h3>
                        </div>
                        <div class="py-2 px-5">
                            <div class=" items-stretch ">
                              <label for="link" class="text-gray-600 text-xs font-normal">Qualquer pessoa com este link pode assistir</label>
                              <div class="relative w-full mt-2">
                                  <div class="absolute text-gray-500 flex items-center px-2 border-r h-full">
                                    <a v-if="!isNaN(parseInt(aula.link))" :href="'https://player.vimeo.com/video/'+aula.link" target="_blank">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link animate-pulse" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                      </svg>
                                    </a>
                                    <a v-else :href="aula.link" target="_blank">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link animate-none" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                      </svg>
                                    </a>
                                  </div>
                                <input v-if="!isNaN(parseInt(aula.link))" id="link" class="flex w-72 h-8 pl-12 pr-5 text-gray-600 bg-gray-100 focus:outline-none focus:border focus:border-indigo-700 text-xs items-center border-gray-300 rounded border whitespace-nowrap truncate" :value="'https://player.vimeo.com/video/'+aula.link" />
                                <input v-else id="link" class="flex w-72 h-8 pl-12 pr-5 text-gray-600 bg-gray-100 focus:outline-none focus:border focus:border-indigo-700 text-xs items-center border-gray-300 rounded border whitespace-nowrap truncate" :value="aula.link" />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FIM Vimeo Dropdown -->
                </td>
                <td class="pr-6 sm:py-4 whitespace-nowrap text-left max-w-[14rem] sm:max-w-[12rem] md:max-w-[18rem]">
                  <div class="text-sm text-gray-900 whitespace-nowrap truncate">{{ aula.nome }}</div>
                  <span class="inline-flex px-2 text-xs font-light leading-5 text-gray-800 bg-gray-200 ">{{ aula.id }}</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap max-w-xs hidden sm:table-cell">
                  <div class="text-sm text-gray-500">Disciplina</div>
                  <div class="text-sm text-gray-900 whitespace-nowrap truncate">{{ aula.disciplina }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap max-w-xs hidden md:table-cell">
                  <div class="text-sm text-gray-500">Curso</div>
                  <div class="text-sm text-gray-900 whitespace-nowrap truncate">{{ aula.curso }}</div>
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap hidden lg:table-cell">
                  {{ aula.data }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 border-2 border-white rounded"
                    v-if="aula.status == 'A'">Ativo</span>
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 border-2 border-white rounded"
                    v-if="aula.status == 'I'">Inativo</span>
                </td>

                <td class="px-1 sm:px-6 py-4 whitespace-nowrap">
                  <div class="text-sm  items-center -space-x-4 hover:space-x-1">
                    <router-link :to="{ name: 'editar_aula', params:{ projeto: projeto, id_aula: aula.id}}">
                      <button
                        class="z-20 flex align-center px-3 py-3 text-blue-700 transition-all bg-blue-100 border-2 border-white rounded active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring "
                        type="button">
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                        &nbsp;&nbsp;Editar
                      </button>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class=" mb-4">
            <Pagination :projeto="projeto" :limit="limit_page" @emit_data="getAulas_pagination" :busca="busca" />
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import Pagination from '@/components/Pagination.vue';
  export default {
    name: "List_Aulas",
    components: { Pagination },
    props: {
      projeto: {
        type: String,
        required:true
      }
    },
    data() {
        return {
            aulas: null,
            aula: null,
            actives: null,
            busca: "",
            periodo: null,
            limit_page: 10,
        };
    },
    methods: {
        async getAulas_pagination( aulas ) {
          this.aulas = aulas;
        },
        async filter_periodo_aula() {
            // console.log(this.periodo);
        }
    }
  }
</script>