<template>
    <div class="flex flex-col md:my-6">
      <div class="overflow-x-auto sm:my-2 lg:mx-8">
        <div class="inline-block min-w-full align-middle border-gray-200 shadow-md bg-gray-50">

          <div class="flex items-center justify-between  m-8">
            <ol class="inline-flex items-center space-x-1 md:space-x-3 ">
              <li class="inline-flex items-center">
                <router-link to="/" class="inline-flex items-center md:text-2xl font-medium uppercase text-gray-500 hover:text-gray-400">
                  {{ projeto }}
                </router-link>
              </li>
              <li class="hidden sm:block">
                <div class="flex items-center text-xs md:text-xl font-medium border rounded-md px-2 py-1 text-gray-50 bg-blue-500 hover:bg-blue-500/90">
                    {{ getTotal() }} Inscrições
                </div>
              </li>
            </ol>
        </div>
          <div class="flex items-center justify-around m-8">
            <Bar_Grafico :db="this.projeto" />
            <Line_Grafico :db="this.projeto" />
          </div>

          <div class="flex justify-between items-start m-8">
              <MultiProgress :db="this.projeto" class="w-[100ch]"/>

              <div class="flex items-center justify-between space-x-3">
                <label for="table-search" class="sr-only">Search</label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input v-model="busca" @input="onSearch" type="text" id="table-search"
                    class="block p-2 pl-10 w-36 sm:w-80 text-sm text-gray-900 bg-white border border-gray-200 focus:outline-none focus:border-blue-400"
                    placeholder="Busca por nome, email ou cidade">
                </div>
  
                <button @click="exportTable()" class="flex mr-3 justify-center bg-transparent hover:bg-sky-500 text-sky-500 hover:text-white py-1.5 px-3 border border-sky-500 hover:border-transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-3">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                    Baixar
                </button>
              </div>
            </div>
            <div class="overflow-auto border-b border-gray-200 shadow-md bg-gray-50 max-h-[70vh]" ref="tableContainer">
            <table class="min-w-full overflow-x-scroll divide-y divide-gray-200">
              <tbody class="bg-white divide-y divide-gray-200 border-t">
                <tr v-for="inscricao in getInscricoes()" :key="inscricao.id" class="transition-all hover:bg-gray-100 hover:shadow-lg">
                    <td class="sm:pl-6 sm:px-1 whitespace-nowrap">
                        <span class="inline-flex px-2 text-xs font-light leading-5 text-gray-800 bg-gray-200 ">{{ inscricao.id }}</span>
                    </td>
                    <td class="pr-6 sm:py-4 whitespace-nowrap text-left max-w-[8rem] sm:max-w-[12rem] md:max-w-[18rem]">
                        <div class="text-sm text-gray-900 whitespace-nowrap truncate">{{ inscricao.firstName }}</div>
                        <span class="inline-flex px-1 text-xs font-light leading-5 text-gray-500 bg-gray-50 ">{{ inscricao.email }}</span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap max-w-xs hidden sm:table-cell">
                        <div class="text-sm text-gray-500">{{ inscricao.profession }}</div>
                        <div class="text-sm text-gray-900 whitespace-nowrap truncate uppercase">
                            <span v-if="inscricao.profession == 'Médico'">{{ inscricao.CRM }}</span>
                            <span v-if="inscricao.profession == 'Farmacêutico'">{{ inscricao.CRF }}</span>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap max-w-xs hidden md:table-cell">
                        <div class="text-sm text-gray-500">Localização</div>
                        <div class="text-sm text-gray-900 whitespace-nowrap truncate"> {{ inscricao.city }}, {{ inscricao.state }}</div>
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap hidden lg:table-cell">
                        {{ new Date(inscricao.createdAt).toLocaleString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                        <span
                        class="inline-flex px-2.5 py-0.5 text-xs font-semibold leading-5 text-green-600 bg-green-100 border-2 border-white rounded-sm"
                        v-if="inscricao.accountStatus == 'Ativa'">Ativo</span>
                        <span
                        class="inline-flex  px-2.5 py-0.5 text-xs font-semibold leading-5 text-red-600 bg-red-100 border-2 border-white rounded-sm"
                        v-else>Inativo</span>
                    </td>
                </tr>
                <tr>
                  <td v-if="getLoadingInscricoes()" colspan="6">
                    <SpinnerComponent class="m-auto" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import SpinnerComponent from '../components/helper/SpinnerComponent.vue'
  import { debounce } from 'lodash';
  import Bar_Grafico from '@/components/Charts/Bar_Grafico.vue'
  import Line_Grafico from '@/components/Charts/Line_Grafico.vue'
  import MultiProgress from '@/components/Charts/MultiProgress.vue'

    export default {
      name: "List_Inscricoes",
      components: { 
        SpinnerComponent,
        Bar_Grafico,
        Line_Grafico,
        MultiProgress
       },
      props: {
        projeto: {
          type: String,
          required:true
        }
      },
      data() {
        return {
            inscricoes: null,
            busca: "",
            limit_page: 15,
            page: 1
        };
      },
      methods: {
        ...mapActions(['fetchAll', 'fetchInscricoes', 'adicionarInscricoes']),
        ...mapGetters(['getInscricoes', 'getTotal', 'getLoadingInscricoes']),
        carregarInscricoes() {
          this.fetchInscricoes({
              db: this.projeto, 
              keyword: this.busca.trim(), 
              limit_page: this.limit_page, 
              pageNumber: this.page
          })
        },
        handleScroll() {
          const tableContainer = this.$refs.tableContainer;
      
          if (tableContainer.scrollTop + tableContainer.clientHeight >= (tableContainer.scrollHeight - 100)) {
            if((this.page * this.limit_page) < this.getTotal()) {
              this.page++;

              this.adicionarInscricoes({
                  db: this.projeto, 
                  keyword: this.busca, 
                  limit_page: this.limit_page, 
                  pageNumber: this.page
              })
            }
          }
        },
        debouncedSearch: debounce(function() {
          this.page = 1;
          this.carregarInscricoes();

        }, 500),
        
        onSearch() {
          this.debouncedSearch();
        },

        async exportTable() {
          const allInscricoes = await this.fetchAll({ db: this.projeto });
          const dados = allInscricoes.map((inscricao) => Object.values(inscricao));
          const headers = Object.keys(allInscricoes[0]).map((header) => header.toUpperCase());
          
          let tabela = '<table><thead><tr>';
          headers.forEach((header) => {
            tabela += '<th>' + header + '</th>';
          });
          tabela += '</tr></thead><tbody>';
          dados.forEach((linha) => {
            tabela += '<tr>';
            linha.forEach((valor) => {
              tabela += '<td>' + valor + '</td>';
            });
            tabela += '</tr>';
          });
          tabela += '</tbody></table>';
          
          const dadosBlob = new Blob(['\ufeff' + tabela], {
            type: 'application/vnd.ms-excel'
          });
          
          const url = window.URL.createObjectURL(dadosBlob);
          const a = document.createElement('a');
          const d = new Date();

          a.href = url;
          // a.download = `Dados [${d.getDate()}-${d.getMonth()} ${d.getHours()}h${d.getMinutes()}]`;
          a.download = `Inscrições LAEF [${d.getDate()} ${d.getMonth()} ${d.getFullYear()}  ${d.getHours()}h${d.getMinutes()}].xls`;

          a.click();
        },
      },
      mounted() {
        const tableContainer = this.$refs.tableContainer;
        tableContainer.addEventListener('scroll', this.handleScroll);
        this.carregarInscricoes();
      }
    }
  </script>