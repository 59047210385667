<template>
  <Navbar />
  <div class="main-container">
    <Form_Aula_AcademiaSBU v-if="String($route.params.projeto) == 'academiaSBU'" :id_aula="parseInt($route.params.id_aula)" :projeto="String($route.params.projeto)" />
    <Form_Aula v-else :id_aula="parseInt($route.params.id_aula)" :projeto="String($route.params.projeto)" />
  </div>
</template>
<script>
import Form_Aula from '@/components/Form_Aula.vue';
import Form_Aula_AcademiaSBU from '@/components/Form_Aula_AcademiaSBU.vue';
import Navbar from '@/components/Navbar.vue'

  export default {
    name: 'FormAulasView',
    components: {
        Navbar,
        Form_Aula,
        Form_Aula_AcademiaSBU
    }
  }
</script>
<style>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .main-container {
    min-height: 800px;
  }



/* =========================== */
/* INICIO DO ESTILO DE VUETIFY */

/* Remove the default button styling in all browsers */
input {
  background-color: transparent;
}

:root {
  --v-theme-background: #fff;
  --v-theme-on-background: #000;
  --v-theme-surface: #fff;
  --v-theme-on-surface: #000;
  --v-theme-overlay-multiplier: 1;
  --v-scrollbar-offset: 0px;
}

/* FIM */
/* =========================== */

</style>
