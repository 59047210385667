<template>
  <Navbar />
  <div class="main-container">
    
    <List_Aulas :projeto="String($route.params.projeto)"/>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import List_Aulas from '@/components/List_Aulas.vue';

  export default {
    data(){
      return {
      }
    },
    components: { 
      Navbar,
      List_Aulas
    }
  }  
</script>
