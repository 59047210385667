<template>
    <Navbar_Login />
    <div class="min-h-screen flex flex-col bg-gradient-to-r ">
        <div class="lg:grid lg:min-h-[93.2vh] lg:grid-cols-12">
            <section class="relative flex items-end h-32 bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
                <img alt="Night"
                    src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg"
                    class="absolute inset-0 object-cover w-full h-full opacity-40" />

                <div class="hidden lg:block lg:relative lg:p-12">
                    <span class="block text-center text-white">
                        <img alt="Vue icon" src="../../public/img/iconUnimagem.png" class="w-8 h-8 sm:h-12 sm:w-12">
                    </span>
                    <h1 class="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                         Administrativo Unimagem
                    </h1>

                    <p class="mt-4 leading-relaxed text-white/90">
                        UNIMAGEM PRODUCOES AUDIOVISUAIS LTDA
                    </p>
                    <p class="mb-8 text-sm leading-relaxed text-white/90">
                        Rua Gustavo Maciel, 22-40. Sala 54, 17012-110 Jardim Nasralla - Bauru - SP
                    </p>
                </div>
            </section>

            <main
                class="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6">
                <div class="max-w-xl lg:max-w-3xl">
                    <div class="relative block -mt-16 lg:hidden">
                        <span class="inline-flex items-center justify-center w-16 h-16 text-blue-600 bg-white rounded-full sm:w-20 sm:h-20">
                                <img alt="Vue icon" src="../../public/img/iconUnimagem.png" class="w-8 h-8 sm:h-12 sm:w-12">

                            </span>

                        <h1 class="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                            Administrativo Unimagem
                        </h1>

                        <p class="mt-4 leading-relaxed text-gray-500">
                            UNIMAGEM PRODUCOES AUDIOVISUAIS LTDA
                        </p>
                        <p class="mb-8 text-sm leading-relaxed text-gray-500">
                            Rua Gustavo Maciel, 22-40. Sala 54, 17012-110 Jardim Nasralla - Bauru - SP
                        </p>
                    </div>
                    <form class="space-y-6 w-full lg:w-96">     
                        <div>
                            <label for="email" class="block text-left mb-2 text-sm font-medium text-gray-900">E-mail</label>
                            <input v-model="email" type="email" name="email" id="email" autocomplete="on"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="name@company.com" required>
                        </div>                
                        <div>
                            <label for="password" class="block text-left mb-2 text-sm font-medium text-gray-900">Senha</label>
                            <input v-model="password" type="password" name="password" id="password" placeholder="••••••••"  autocomplete="on"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required>
                        </div>
                        <div class="flex items-center justify-end mt-10">
                            <button :class="getLoadingUser && 'cursor-not-allowed'" @click.prevent="submitLogin" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center justify-center flex">
                                <div class="flex " v-if="!!getLoadingUser">
                                    <SpinnerComponent class="m-auto h-6" />
                                </div>
                                <span v-else class="mx-4 text-center">
                                    Entrar
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SpinnerComponent from '@/components/helper/SpinnerComponent.vue'
    import Navbar_Login from '@/components/Navbar_Login.vue'
    export default {
        name: 'LoginView',
        components: {
            SpinnerComponent,
            Navbar_Login
        },
        data() {
            return {
                email: "",
                password: ''
            }
        },
        methods: {
            ...mapActions(['loginUser', 'registerUser']),
            submitLogin(){
                if(!this.getLoadingUser){
                    if(this.email && this.password){
                        this.loginUser({
                            email: this.email,
                            password: this.password
                        });
                    }
                }
            }
        },
        computed: {
            ...mapGetters(['getLoadingUser'])
        }
    }
</script>

<style lang="scss" scoped>

    $_spinner: rgb(87, 87, 87);

    ._spinner {
        border-top-color: #c7c7c7;
        border-right-color: $_spinner;
        border-left-color: $_spinner;
        border-bottom-color: $_spinner;
    }
    
    .login__component {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        display: flex;

        .__form {
            width: 100%;
            flex-grow: 1;
            background-color: #F1F1F1;
            max-width: 400px;
            display: flex;
            align-items: center;
            position: relative;

            form {
                width: 100%;
                padding: 2em;
                margin-bottom: 40%;
                display: flex;
                flex-direction: column;

                label {
                    font-size: .9rem;
                    font-weight: bold;
                    color: #707070;
                    margin-bottom: .8em;

                    &:not(:first-child){
                        margin-top: 1.5em;
                    }
                }
                
                input[type="text"], input[type="password"]{
                    border: 2px #D9D9D9 solid;
                    
                    border-radius: 6px;
                    background-color: rgb(255, 255, 255);       
                    color: rgb(82, 82, 82);
                    padding: 1.3em 1em;
                    width: 100%;
                    font-family: inherit;
                    font-weight: semi-bold;
                    font-size: .878em;

                    &:focus {
                        outline: none;
                    }
                }

                input[type="submit"] {
                    background-color: #559BD8;
                    border: none;
                    width: 100%;
                    max-width: 144px;
                    color: #f3f3f3;
                    padding: 1em 0;
                    border-radius: 50em;
                    font-size: 0.78em;
                    cursor: pointer;
                }

                > div {
                    margin-top: 2em;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    a {
                        font-size: .8rem;
                        font-weight: bold;
                        color: #484848;
                        text-decoration: none;
                        margin-right: 1em;
                    }
                }

                input:focus {
                    outline: none;
                }
            }
        }

        section {
            width: 100%;
            flex-grow: 1;
            
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    

</style>