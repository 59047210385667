<template>
  <Navbar />
  <div class="main-container">

    <List_Inscricoes :projeto="String($route.params.projeto)"/>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import List_Inscricoes from '@/components/List_Inscricoes.vue';

  export default {
    data(){
      return {
      }
    },
    components: { 
      Navbar,
      List_Inscricoes
    }
  }  
</script>
