<template>
    <Navbar />
    <div class= "mt-8">
      <List_Projetos v-for="project in getProjects()" :key="project.id"
        :projeto_name="project.subtitle" 
        :img="project.img" 
        :descricao="project.descricao" 
        :type="project.type"
        :db="project.db"/>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Navbar from '@/components/Navbar.vue'
  import List_Projetos from '@/components/List_Projetos.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      projects: null
    }
  },
  components: {
    Navbar,
    List_Projetos
  },
  methods: {
    ...mapGetters(['getProjects', 'getLoadingProject']),
  },
}
</script>
