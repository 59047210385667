<template>
  <header class="hidden sm:block bg-white border-b border-gray-300 shadow-lg">
    <div class="flex items-center h-16 px-4 mx-auto max-w-screen-xl gap-8 sm:px-6 lg:px-8">
        <router-link to="/login">
            <img alt="Vue logo" src="../../public/img/logo.png" class="h-10">
        </router-link>
      <div class="flex items-center justify-end flex-1 md:justify-between">
        <div></div>
        <div class="flex items-center gap-4">
          <div class="sm:gap-4 sm:flex">
            <router-link to="/login">
              <a @click="isOpenLogin = true"  href="javascript:void(0);" class="hidden sm:block  px-5 py-2.5 text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 transition">
                Login
              </a>
            </router-link>
            <span class="block px-5 py-2.5 whitespace-nowrap text-sm font-medium text-sky-600 bg-gray-100 hover:text-sky-600/75 transition">
              Cadastrar-se
            </span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name: "NavbarLoginComponent",
    
    data() {
      return {
        showDropdownNav: 1,
        user: {
          firstName: '',
        }
      }
    },
    methods:{
    },
  }
</script>

<style scoped>
  nav {
    padding: 30px;
  }

  nav a:hover {
    color: rgb(2 132 199 / 0.75);
  }

  nav a {
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    color: rgb(2 132 199);
  }

  nav a.router-link-exact-active {
    color:  rgb(0, 61, 91);
    font-weight: 600;
}
</style>
