import AuthService from '../../services/AuthService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || { firstName: ''}
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.user = payload.user
        state.token = payload.token
        if(router.currentRoute.value.path === '/login' && !state.redirect){
            router.push({path: `/`});
        }
        state.redirect = null;
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.token = payload.token;
        state.user = payload.user;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('notifications');
        localStorage.removeItem('projects');

        state.token = null;
        state.isAuthenticated = false;
        state.user = null;
        state.projects = null;

        if(router.currentRoute.value.path !== '/login'){
            router.push({path: `/login`});
        }
    },
}

const actions = {
    async loginUser({commit, dispatch}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            commit('SET_USER', response.data);
            dispatch('getPermissions', { root: true })
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async AuthUser({commit}){
        try {
            const response = await AuthService.auth();
            commit('AUTH_USER', response.data);
            return response.data;
        } catch (error) {
            commit('LOG_USER_OUT');
        }
    }
}

const getters = {
    getUser: state => state.user,
    getUserName: state => state.user.firstName,
    getLoadingUser: state => state.loadingUser,
}

export default {
    state,
    getters,
    mutations,
    actions
};