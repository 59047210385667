import axios from 'axios'

const state = () => ({
    loadingInscricoes: false,
    inscricoes: null,
    total: 0,
    pages: 0,
    page: 1,
    database: 'laef2023',
    inscricoesProfession: [
        {
            "profession": "Médico",
            "percentage": "0"
        },
        {
            "profession": "Farmacêutico",
            "percentage": "0"
        },
        {
            "profession": "Colaborador BI",
            "percentage": "0"
        }
    ],
    inscricoesSpecialty: null
})
  
const mutations = {
    SET_INSCRICOES: (state, payload) => {
        state.inscricoes = payload
    },
    SET_TOTAL: (state, payload) => {
        state.total = payload
    },
    ADICIONAR_INSCRICOES(state, inscricoes) {
        state.inscricoes = [...state.inscricoes, ...inscricoes]
    },
    SET_LOADING_INSCRICOES: (state, payload) => {
        state.loadingInscricoes = payload
    },
    SET_INS_PROFESSION: (state, payload) => {
        state.inscricoesProfession = payload;
    },
    SET_INS_SPECIALTY: (state, payload) => {
        state.inscricoesSpecialty = payload;
    },
}

const actions = {
    async fetchAll({commit, rootState}, db){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const res = await  axios.post(`${process.env.VUE_APP_URL}/inscricoes/all`, db, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_LOADING_INSCRICOES', false)
            
            return res.data.inscricoes
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            // commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },

    async fetchInscricoes({commit, rootState}, { db, keyword, limit_page, pageNumber} ){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const response = await axios.post(`${process.env.VUE_APP_URL}/inscricoes`, { db, keyword, limit_page, pageNumber}, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_INSCRICOES', response.data.inscricoes)
            commit('SET_TOTAL', response.data.total)
            commit('SET_LOADING_INSCRICOES', false)
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async adicionarInscricoes({commit, rootState}, { db, keyword, limit_page, pageNumber} ){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            axios.post(`${process.env.VUE_APP_URL}/inscricoes`, { db, keyword, limit_page, pageNumber}, {headers: { authorization: `Bearer ${rootState.user.token}` }})
                .then(response => {
                    commit('ADICIONAR_INSCRICOES', response.data.inscricoes)
                    commit('SET_TOTAL', response.data.total)
                    commit('SET_LOADING_INSCRICOES', false)
                })
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            // commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchInscricoesState({commit, rootState}, db){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const res = await  axios.post(`${process.env.VUE_APP_URL}/inscricoes/inscricoesstate`, db, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_LOADING_INSCRICOES', false)
            
            return res.data
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchInscricoesWeek({commit, rootState}, db){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const res = await  axios.post(`${process.env.VUE_APP_URL}/inscricoes/inscricoesweek`, db, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_LOADING_INSCRICOES', false)
            
            return res.data
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchinscricoesProfession({commit, rootState}, db){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const res = await  axios.post(`${process.env.VUE_APP_URL}/inscricoes/inscricoesProfession`, db, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_INS_PROFESSION', res.data);
            commit('SET_LOADING_INSCRICOES', false)
            
            return res.data
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchinscricoesSpecialty({commit, rootState}, db){
        try{
            commit('SET_LOADING_INSCRICOES', true)
            const res = await  axios.post(`${process.env.VUE_APP_URL}/inscricoes/inscricoesSpecialty`, db, {headers: { authorization: `Bearer ${rootState.user.token}` }})
            commit('SET_INS_SPECIALTY', res.data);
            commit('SET_LOADING_INSCRICOES', false)
            
            return res.data
        } catch (error) {
            commit('SET_LOADING_INSCRICOES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },

}

const getters = {
    getInscricoes: state => state.inscricoes,
    getTotal: state => state.total,
    getLoadingInscricoes: state => state.loadingInscricoes,
    getInscricoesProfession: state => state.inscricoesProfession,
    getInscricoesSpecialty: state => state.inscricoesSpecialty
}

export default {
    state,
    getters,
    mutations,
    actions
};