<template>
    <div class="vue-modal" v-show="open_confirmacao">
        <div class="vue-modal-inner" v-show="open_confirmacao">
          <div class="vue-modal-content">
            <div id="authentication-modal" @click.self="close_confirmacao" tabindex="-1" aria-hidden="true" class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center">
                <div class="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div class="relative bg-white shadow" >
                        <button @click="close_confirmacao" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                                    Confirmação
                                </h5>
                                <button type="button"
                                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        <div class="modal-body relative p-4 text-left">
                            <p>Deseja confirmar as ações realizadas ?</p>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button @click="close_confirmacao" type="button"
                                class="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal">
                                Cancelar
                            </button>
                            <button @click="this.$emit('emit_data', true)" class="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
              </div> 
          </div>
        </div>
       
    </div>
</template>

<script>
  import { onMounted, onUnmounted } from 'vue';
export default {
  name: "Modal_Confirmacao",
  props: {
      open_confirmacao: {
          type: Boolean,
          required: true,
      }
  },
  data() {
      return {
       
      };
  },
  methods: {
     
  },
  mounted() {
     
  },
  setup(_, { emit }) {
      const close_confirmacao = () => {
          emit("close_confirmacao");
      };
      const handleKeyup = (event) => {
          if (event.keyCode === 27) {
              close_confirmacao();
          }
      };
      onMounted(() => document.addEventListener("keyup", handleKeyup));
      onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
      return { close_confirmacao };
  }
};
</script>