<template>
   
      <div class="vue-modal" v-show="open_relacionar">
          <div class="vue-modal-inner">
            <div class="vue-modal-content">
              <div id="authentication-modal" @click.self="close_modal_relacionar" tabindex="-1" aria-hidden="true" class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center">
                  <div class="relative p-4 w-full max-w-md h-full md:h-auto">
                      <div class="relative bg-white shadow" >
                          <button @click="close_modal_relacionar" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          </button>
                          <div class="py-6 px-6 lg:px-8">
                              <h3 class="mb-4 text-xl font-medium text-gray-900">Criar Relacionamento</h3>
                              <form class="space-y-6" action="" @submit.prevent="get_dados_tabela_colunas($event)">
                                  <div>
                                      <label for="curso" class="flex items-start mb-2 text-sm font-medium text-gray-900">Tabela: </label>
                                      <select v-model="tabela"  name="curso" id="curso" @change="getColunas(tabela)" class="capitalize bg-gray-50 border-2 border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5" required>
                                        <option value="" selected disabled>Selecione a Tabela</option>
                                        <option v-for="(table, index) in tabelas" :key="index" :value="index" class="capitalize">{{ table.name }}</option>
                                      </select>
                                  </div>
                                  <div v-show="coluna_pesquisa">
                                      <label for="curso" class="flex items-start mb-2 text-sm font-medium text-gray-900">Comparar com: </label>
                                      <select v-model="coluna_pesquisa"  name="curso" id="curso" class="capitalize bg-gray-50 border-2 border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5" required>
                                        <option value="" selected disabled>Selecione uma Coluna</option>
                                        <option v-for="(coluna, index) in colunas" :key="index" :value="coluna.Field" class="capitalize">{{ coluna.Field }}</option>
                                      </select>
                                  </div>
                                  <div v-show="coluna_subistituir">
                                      <label for="curso" class="flex items-start mb-2 text-sm font-medium text-gray-900">Subirtituir por: </label>
                                      <select v-model="coluna_subistituir"  name="curso" id="curso" class="capitalize bg-gray-50 border-2 border-gray-200 text-gray-900 text-sm focus:border-blue-400 block w-full p-2.5" required>
                                        <option value="" selected disabled>Selecione uma Coluna</option>
                                        <option v-for="(coluna, index) in colunas" :key="index" :value="coluna.Field" class="capitalize">{{ coluna.Field }}</option>
                                      </select>
                                  </div>
                                  <button type="submit" class="w-full text-white bg-emerald-400 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium text-sm px-5 py-2.5 text-center">Relacionar</button>
                              </form>
                          </div>
                      </div>
                  </div>
                </div> 
                <div  v-show="isLoading" class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center">
                    <SpinnerComponent />
                </div>
            </div>
          </div>
      </div>
  </template>
  
  <script>
    import SpinnerComponent from '@/components/helper/SpinnerComponent.vue';
    import { onMounted, onUnmounted } from 'vue';
  export default {
    name: "Modal_New_Disciplina",
    components: { SpinnerComponent },
    props: {
        open_relacionar: {
            type: Boolean,
            required: true,
        },
        projeto: {
            type: String,
            required: true
        },
        coluna_prop: {
            required: true  
        }
    },
    data() {
        return {
            tabelas: null,
            tabela: null,

            isLoading: false,
            
            colunas: null,
            coluna_pesquisa: null,
            coluna_subistituir: null,
        };
    },
    methods: {
        async getColunas(index) {
            this.coluna_pesquisa = true;
            this.coluna_subistituir = true;
            const tabela = this.tabelas[Number(index)];

            this.colunas = tabela.colunas;
            this.selecionar_automaticamente();
        },
        async getTabelas() {
            const req_tables = await fetch(`${process.env.VUE_APP_URL}/tabela/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Database' : this.projeto
                },
            });
            const tables = await req_tables.json();

            this.tabelas = tables.data;
        },
        selecionar_automaticamente() {
            // Buscando pelo elmento a ser Comparado 
            let index_pesquisar = this.colunas.find(function(element) {
                return element.Field == 'nome' ||  element.Field == 'name' ;
            });
            if(index_pesquisar){
                this.coluna_pesquisa = index_pesquisar.Field;
            }

            // Buscando pelo elmento a ser Substituido 
            let index_subistituir = this.colunas.find(function(element) {
                return element.Field == 'id';
            });
            if(index_subistituir){
                this.coluna_subistituir = index_subistituir.Field;
            }
        },
        async get_dados_tabela_colunas(e) {
            e.preventDefault()
            this.isLoading = true;
            const tabela = this.tabelas[Number(this.tabela)];

            try {
                const req = await fetch(`${process.env.VUE_APP_URL}/row/${tabela.name}/`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Database" : this.projeto
                    },
                });
                await req.json().then((res) => {
                this.$emit('emit_data', res, this.coluna_pesquisa, this.coluna_subistituir, tabela.name);
                this.$emit("close_modal_relacionar");
                this.isLoading = false;

                }).catch((error) => {
                    console.log("error_msg", "Houve um erro:" + error);
                    this.isLoading = false;
                });
            }
            catch (error) {
                console.log("error_msg", "Houve um erro:" + error);
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getTabelas();
    },
    setup(_, { emit }) {
        const close_modal_relacionar = () => {
            emit("close_modal_relacionar");
        };
        const handleKeyup = (event) => {
            if (event.keyCode === 27) {
                close_modal_relacionar();
            }
        };
        onMounted(() => document.addEventListener("keyup", handleKeyup));
        onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
        return { close_modal_relacionar };
    },
};
  </script>